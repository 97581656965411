import React, { useEffect, useRef, useCallback, useLayoutEffect } from "react";
import ReactDOM from "react-dom";

export default function Modal(props) {
  const {
    open,
    handleClose,
    children,
    modalContainerStyle, // = {},
    zIndex,
    data, //= { opciones: { id_a: "" } },
    className,
  } = props;

  const d = open ? "block" : "none";
  modalContainerStyle.display = d;
  // modalContainerStyle.minWidth = "60vw";

  const modalRef = useRef(null);

  const adjustModalPosition = useCallback(
    ({ modalContainer, windowHeight }) => {
      const modalRect = modalContainer.getBoundingClientRect();
      const modalTop = modalRect.top;

      if (modalTop < 0) {
        const offset = Math.abs(modalTop) + Math.abs(windowHeight) - 500;
        modalContainer.style.top = offset + "px";
      }
      const M = document.getElementById(data.opciones.id_a);

      if (M) {
        M.scrollIntoView({
          behavior: "smooth",
          block: modalTop < 0 ? "start" : "center",
        });
      }
    },
    [data.opciones.id_a]
  );

  useEffect(() => {
    const modalContainer = modalRef.current;
    const windowHeight = window.innerHeight;

    // Ajustar la posición del modal cuando se carga la página
    adjustModalPosition({ modalContainer, windowHeight });

    return () => {};
  }, []);

  useLayoutEffect(() => {
    // CENTRAR EL MODAL AL RENDERIZAR
    // Configurar un observador de mutación
    const modalContainer = modalRef.current;
    const windowHeight = window.innerHeight;
    const targetNode = modalRef.current;
    const observer = new MutationObserver(() => {
      adjustModalPosition({ modalContainer, windowHeight });
      observer.disconnect();
    });

    // Observar cambios en el tamaño del componente
    const config = {
      attributes: false,
      childList: true,
      subtree: true,
    };
    observer.observe(targetNode, config);

    // Detener la observación cuando el componente se desmonte
    return () => {
      observer.disconnect();
    };
  }, [adjustModalPosition, data.opciones.id_a]);

  useEffect(() => {
    const M = document.getElementById(data.opciones.id_a);

    if (M) {
      M.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [data.opciones.id_a]);

  return ReactDOM.createPortal(
    <>
      <div
        className="pantalla-fondo-modal"
        onClick={() => handleClose(false)}
        style={{ display: d, zIndex }}
      ></div>

      <div
        id={data.opciones.id_a}
        className={`pantalla-modal-container ${className}`}
        style={modalContainerStyle}
        ref={modalRef}
      >
        <div className="cerrar-modal">
          <span
            style={{
              fontSize: "30px",
              cursor: "pointer",
              borderRadius: "3px",
            }}
            onClick={() => handleClose(false)}
          >
            ×
          </span>
        </div>
        {children}
      </div>
    </>,
    document.getElementById("modal-root")
  );
}

export const MemoizedModal = React.memo(Modal);

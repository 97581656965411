import React, { useContext, useState, useEffect, useRef } from "react";
import { withTools } from "../../../../helper/withTools";
import FuncionesContext from "../../../../context/FuncionesContext";
import ModalesContext from "../../../../context/ModalContext";
import TextField from "@mui/material/TextField";
import Axios from "axios";
import { farmageo_api } from "../../../../../../config";

const InputBuscador = (props) => {
  const { data, cab, campokey, id_elemento } = props;
  const {
    seleccion_multiple,
    columna_ida_valor,
    columna_ida_mostrar,
    filtro_ida_valor,
    filtro_ida_recuperacion,
    pantalla_ida,
  } = cab;

  const { superSubmit } = useContext(FuncionesContext);

  const [termino, setTermino] = useState("");
  const value = data[campokey];
  const [oid, setId] = useState("");
  const [select_data, setSelectData] = useState({});
  const [disabled, setdisabled] = useState(
    data[cab.id_a + "_disabled"] ?? true
  );

  const vref = useRef();
  vref.current = data[campokey];

  const handleInput = (e) => {
    const { value } = e.target;
    setTermino(value);

    setId("");
  };

  const { enviarAModal } = useContext(FuncionesContext);
  const { cerrarModal } = useContext(ModalesContext);

  const filtro = {
    [filtro_ida_valor]: termino, // filtro id_a a pasar al listado que se abre en modal
  };

  class handleSubmit {
    // const data_send = data_selected.map((d) => {
    //   return d[columna_ida_valor];
    // });
    constructor(e, row, data_selected) {
      this.data_selected = data_selected;
      this.row = row;
      this.event = e;
    }

    static mulitple(data_selected) {
      try {
        const data_send = data_selected.map((d) => {
          return d[columna_ida_valor];
        });
        superSubmit({
          valor: data_send,
          id_a: cab.id_a,
          update_id: data[cab.update_id_alias] ?? cab.update_id,
          handleCancelar: () => {},
          cab,
          data,
        });

        cerrarModal(pantalla_ida);
      } catch (err) {
        console.log(err);
      }
    }

    static simple(data_selected) {
      const data_send = data_selected[columna_ida_valor];

      superSubmit({
        valor: data_send,
        id_a: cab.id_a,
        update_id: data[cab.update_id_alias] ?? cab.update_id,
        handleCancelar: () => {},
        cab,
        data,
      });
      setTermino(() => {
        return data_selected[columna_ida_mostrar];
      });
      cerrarModal(pantalla_ida);
    }
  }

  const opMultiple = {
    listado_seleccion: "s",
    showSelectAllCheckbox: false,
    showTextRowsSelected: false,
    onSelectionChange: (rowData) => {
      const noid = rowData.map((o) => o[columna_ida_valor]); // columna a setear como value "MONODRO_LST_ID"
      setTermino(rowData.map((t) => t[columna_ida_mostrar]).toString()); // columnda a mostrar "MONODRO_LST_NOMBRE"
      //funcion de guardar
      setSelectData(() => {
        return rowData;
      });
      setId(noid);
    },
    listado_filtro_termino: termino,
    filtrosDesdeInput: filtro,
    activarListado: !!termino,
    iniciar_activo: "n",
    submit_button: true,
    submit_button_handleSubmit: handleSubmit.mulitple,
    min_width_modal: cab.min_width_modal,
  };

  const opSimple = {
    onRowClick: (e, rowData, data) => {
      const noid = rowData[columna_ida_valor]; // columna a setear como value
      // columnda a mostrar
      setId(() => noid);
      // funcion de guardar
      setSelectData(() => rowData);
    },
    onRowDoubleClick: (e, rowData) => {
      const noid = rowData[columna_ida_valor]; // columna a setear como value
      setTermino(() => rowData[columna_ida_mostrar]); // columnda a mostrar
      setId(() => noid);
      //funcion de guardar
      setSelectData(() => rowData);
      handleSubmit.simple(rowData);
      cerrarModal(pantalla_ida);
    },
    rowStyle: true,
    listado_filtro_termino: termino,
    filtrosDesdeInput: filtro,
    activarListado: !!termino,
    iniciar_activo: "n", // como en realidad llama a la pantalla, el atributo inicial del listado es quien prevalece
    submit_button: true,
    submit_button_handleSubmit: handleSubmit.simple,
    min_width_modal: cab.min_width_modal,
  };

  const showModal = () =>
    enviarAModal(
      pantalla_ida, // Listado para abrir en modal
      {},
      seleccion_multiple === "s" ? opMultiple : opSimple
    );

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      showModal();
    }
  };

  useEffect(() => {
    const componentIsMounted = { current: true }; // Utilizar una referencia mutable

    const fetchData = async () => {
      try {
        if (
          value &&
          value !== "" &&
          filtro_ida_recuperacion &&
          filtro_ida_recuperacion !== ""
        ) {
          setdisabled(true);
          const filtros = {
            [filtro_ida_recuperacion]: value,
            activarListado: "s",
          };

          const response1 = await Axios.post(
            farmageo_api + "/ib/" + pantalla_ida,
            { ...filtros },
            { params: filtros }
          );

          // Verificar si el componente está montado antes de realizar más operaciones
          if (!componentIsMounted.current) return;

          const term =
            response1.data.configuraciones[0].datos[0][columna_ida_mostrar];
          setTermino(() => term);
          setdisabled(() => false);
        } else {
          setdisabled(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // Manejar el error según sea necesario
      }
    };

    fetchData();

    return () => {
      componentIsMounted.current = false;
    };
  }, [columna_ida_mostrar, filtro_ida_recuperacion, pantalla_ida, value]);

  return (
    <div id={id_elemento} style={{ display: "flex", width: "100%" }}>
      {cab.nombre ? (
        <div className="vista_label vista_label_fuente">{cab.nombre}:</div>
      ) : (
        <></>
      )}
      <div className="filtroBuscador" style={{ width: "100%" }}>
        <TextField
          onChange={handleInput}
          onKeyDown={handleKeyDown}
          value={termino}
          style={{
            width: "82%",
            padding: "0 0",
            backgroundColor: disabled ? "#f0f0f0" : "inherit",
            marginRight: "6px",
          }}
          type="text"
          variant="outlined"
          label={props.label}
          disabled={disabled}
        />
        <button onClick={() => showModal()} className="InputBusquedaLupa">
          <i class="fa fa-search" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  );
};

export default withTools(InputBuscador);

import React from "react";
import { Link } from "react-router-dom";
import EnlaceAModal from "./EnlaceAModal";
import EnlaceADescarga from "./EnlaceADescarga";

const Enlace = ({ data, cab, hijos, campokey, id_elemento, qsBody }) => {
  const e = cab.enlace
    ? cab.enlace_alias
      ? data[cab.enlace_alias]
        ? data[cab.enlace_alias]
        : cab.enlace
      : cab.enlace
    : undefined;

  const parametros_valores = cab.enlace_parametros_alias
    ? data[cab.enlace_parametros_alias]
      ? data[cab.enlace_parametros_alias]?.toString().split(",")
      : cab.enlace_parametros?.toString().split(",")
    : undefined;

  const en = cab.enlace_parametros_nombres_alias
    ? data[cab.enlace_parametros_nombres_alias]
      ? data[cab.enlace_parametros_nombres_alias]?.toString().split(",")
      : cab.enlace_parametros_nombres?.toString().split(",")
    : cab.enlace_parametros_nombres?.toString().split(",");

  const id_a = data[cab.enlace_id_a_alias]
    ? data[cab.enlace_id_a_alias]
    : cab.enlace_id_a
    ? cab.enlace_id_a
    : "";

  let parametros = "";
  let paramObj = {};
  if (parametros_valores && en) {
    parametros = "?";
    parametros_valores.forEach((qP, i) => {
      parametros = parametros.concat("&" + en[i] + "=" + qP);
      paramObj[en[i]] = qP;
    });
  }

  // if (cab.enlace_id_a) {
  //   return process.env.PUBLIC_URL + e + id_a + parametros;
  // }

  const enlace =
    cab.enlace_externo === "s"
      ? e + parametros
      : process.env.PUBLIC_URL + "/#" + e + id_a + parametros;

  const nombre = (() => {
    if (cab.nombre_alias) {
      return data[cab.nombre_alias];
    }
    return cab.nombre;
  })();

  if (cab.target === "modal") {
    return (
      <EnlaceAModal
        nombre={nombre}
        paramObj={paramObj}
        cab={cab}
        data={data}
        id_elemento={id_elemento}
        qsBody={qsBody}
        enlace_id_a={id_a}
        campokey={campokey}
      />
    );
  }

  if (cab.target === "_blank") {
    return (
      <div id={id_elemento} className="Listado_Switch_Enlace">
        <Ancla
          cab={cab}
          enlace={enlace}
          data={data}
          hijos={hijos}
          target={cab.target}
        />
      </div>
    );
  }

  if (cab.target === "descarga") {
    const archivo_nombre =
      data[cab.id_a + "_archivo_nombre"] ??
      cab.archivo_nombre ??
      e.split("/").pop();
    return (
      <div
        id={id_elemento}
        className={`Listado_Switch_Enlace ${data[cab.id_a + "_className"]}`}
        style={{}}
      >
        <a
          id={id_elemento + "_a"}
          href={e}
          rel="noopener noreferrer"
          download={archivo_nombre}
        >
          <div
            style={{
              textAlign: cab.align ?? "center",
            }}
          >
            {cab.imagen_url ? (
              <img
                style={{ cursor: "pointer" }}
                height={"40px"}
                src={cab.imagen_url}
                alt="imagen"
              />
            ) : (
              <>
                {cab.boton_texto_alias
                  ? data[cab.boton_texto_alias]
                  : cab.boton_texto}
              </>
            )}

            {hijos}
          </div>
        </a>
      </div>
    );
  }
  if (cab.target?.toLowerCase() === "descargapost") {
    return (
      <EnlaceADescarga
        id_elemento={id_elemento}
        data={data}
        cab={cab}
        e={e}
        hijos={hijos}
      />
    );
  }

  return (
    <div id={id_elemento} className="Vista_Switch_Enlace">
      {cab.enlace_externo === "s" ? (
        <Ancla
          cab={cab}
          enlace={enlace}
          data={data}
          hijos={hijos}
          target="_self"
        />
      ) : (
        <Link to={{ pathname: `${e + id_a}`, search: parametros }}>
          <div
            style={{
              textAlign: "center",
            }}
          >
            {cab.imagen_url ? (
              <img
                style={{ cursor: "pointer" }}
                height={"40px"}
                src={cab.imagen_url}
                alt="imagen"
              />
            ) : (
              <>
                {cab.boton_texto_alias
                  ? data[cab.boton_texto_alias]
                  : cab.boton_texto}
              </>
            )}

            {hijos}
          </div>
        </Link>
      )}
    </div>
  );
};

export default Enlace;

const Ancla = ({ cab, enlace, data, hijos, target }) => {
  return (
    <a target={target} href={enlace} rel="noopener noreferrer">
      <div
        style={{
          textAlign: "center",
        }}
      >
        {cab.imagen_url ? (
          <img
            style={{ cursor: "pointer" }}
            height={"40px"}
            src={cab.imagen_url}
            alt="imagen"
          />
        ) : (
          <>
            {cab.boton_texto_alias
              ? data[cab.boton_texto_alias]
              : cab.boton_texto}
          </>
        )}

        {hijos}
      </div>
    </a>
  );
};

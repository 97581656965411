import React, { useState, useMemo, useCallback } from "react";
import {
  Map,
  Marker,
  GoogleApiWrapper,
  // InfoWindow
} from "google-maps-react";
import InfoWindow from "./MapaWindow";
import { google_api } from "../../../../config";
import MapaInfo from "./MapaInfo";

export function Mapa(props) {
  const { data, cab, display } = props;

  // console.log(cab);

  const mapa_info = cab.sc_hijos?.filter((f) => f.componente === "mapa_info");
  const hayMapaInfo = mapa_info.length > 0;

  const lat_ = cab.latitud_id_a;
  const l = cab.id_a + "_mapa_latitud";
  const long_ = cab.longitud_id_a;
  const o = cab.id_a + "_mapa_longitud";

  const zoom = cab.mapa_zoom ? Number(cab.mapa_zoom) : lat_ && long_ ? 15 : 3;

  const latm = useMemo(
    () => data.reduce((agg, d) => Number(agg) + Number(d[l]), 0) / data.length,
    [data, l]
  );
  const lngm = useMemo(
    () => data.reduce((agg, d) => Number(agg) + Number(d[o]), 0) / data.length,
    [data, o]
  );

  const [activeMarker, setActiveMarker] = useState(null);
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [selectedElement, setSelectedElement] = useState(null);
  const [hoverTimeout, setHoverTimeout] = useState(null);

  const handleMouseOver = useCallback(
    (props, marker) => {
      if (!hayMapaInfo) return;
      if (selectedElement?._key !== props._key) {
        clearTimeout(hoverTimeout);
        setSelectedElement(props);
        setActiveMarker(marker);
        setShowInfoWindow(true);
      }
    },
    [hayMapaInfo, hoverTimeout]
  );

  const handleMouseOut = useCallback(() => {
    if (!hayMapaInfo) return;
    const timeout = setTimeout(() => {
      setSelectedElement(null);
      setActiveMarker(null);
      setShowInfoWindow(false);
    }, 300); // Adjust the delay as needed

    setHoverTimeout(timeout);
  }, [hayMapaInfo]);

  const memoizedMarkers = useMemo(() => {
    return data.map((d) => {
      const lat__ = Number(d[l]);
      const long__ = Number(d[o]);

      return (
        <Marker
          key={`${d._key}`}
          position={{
            lat: lat__,
            lng: long__,
          }}
          icon={{
            url: require("../../../../assets/images/Logo-pointer.svg"),
            anchor: new props.google.maps.Point(25, 25),
            scaledSize: new props.google.maps.Size(25, 25),
          }}
          onMouseover={
            cab.mapa_OnMouseOver === "s"
              ? (props, marker) => handleMouseOver(d, marker)
              : undefined
          }
          onMouseout={
            cab.mapa_OnMouseOver === "s" ? () => handleMouseOut() : undefined
          }
          onClick={
            cab.mapa_OnMouseOver !== "s"
              ? (props, marker) => handleMouseOver(d, marker)
              : undefined
          }
          optimized
          clickable
        />
      );
    });
  }, [
    data,
    l,
    o,
    props.google.maps.Point,
    props.google.maps.Size,
    handleMouseOut,
    handleMouseOver,
  ]);

  return (
    <div
      className="map_container"
      style={{ height: cab.height || "200px", display }}
      id={cab.id_a}
    >
      <Map
        containerStyle={{ width: "100%" }}
        google={props.google}
        className="map"
        zoom={zoom}
        centerAroundCurrentLocation={true}
        initialCenter={{
          lat: latm ?? -38.416097,
          lng: lngm ?? -63.616672,
        }}
        style={{ flex: 1, display: "flex", width: "100%" }}
      >
        {memoizedMarkers}

        <InfoWindow
          visible={showInfoWindow}
          marker={activeMarker}
          onCloseClick={() => {
            handleMouseOut();
          }}
        >
          {selectedElement ? (
            <MapaInfo
              selectedElement={selectedElement}
              mapaInfo={mapa_info[0]}
              cab={cab}
              data={data}
            />
          ) : (
            <></>
          )}
        </InfoWindow>
      </Map>
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: google_api,
})(Mapa);

import React from "react";
import { OMensaje } from "./Mensaje";

import { Paper, Typography } from "@mui/material";

export default function Mensaje(props) {
  const { configuracion } = props;
  const { opciones, datos } = configuracion;
  const { id_a } = opciones;

  const titulo = datos[opciones.id_a + "_titulo"] ?? opciones.titulo;
  const styles = {
    gridColumn: opciones.grid_span ?? "1 / -1",
    border: "none",
    marginBottom: 0,
  };
  return (
    <>
      <Paper id={id_a} style={styles} elevation={0}>
        {titulo ? <Typography variant="titulo">{titulo}</Typography> : <></>}
        <OMensaje {...props} />
      </Paper>
    </>
  );
}

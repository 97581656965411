import React, { useContext } from "react";

import FuncionesContext from "../../../context/FuncionesContext";

function EnlaceADescarga({ id_elemento, data, cab, e, hijos }) {
  const { descargar } = useContext(FuncionesContext);
  const ruta = e + data[cab.id_a + "_archivo_nombre"];

  return (
    <div
      id={id_elemento}
      className={`Listado_Switch_Enlace ${data[cab.id_a + "_className"]}`}
      style={{}}
      onClick={() => descargar(ruta)}
    >
      <div
        style={{
          textAlign: cab.align ?? "center",
        }}
      >
        {cab.imagen_url ? (
          <img
            style={{ cursor: "pointer" }}
            height={"40px"}
            src={cab.imagen_url}
            alt="imagen"
          />
        ) : (
          <>
            {cab.boton_texto_alias
              ? data[cab.boton_texto_alias]
              : cab.boton_texto}
          </>
        )}

        {hijos}
      </div>
    </div>
  );
}

export default EnlaceADescarga;

import React, { useContext, useState } from "react";
import FuncionesContext from "../../../context/FuncionesContext";
import TextFieldEditable from "../../../components/TextFieldEditable";
import "../Vista.scss";
import { validarRegex } from "../../../context/FuncionesContext";
import { useRefDataStore } from "../../../../..";

const InputTextEditable = ({ data, cab, campokey, Context, id_elemento, valor, updateid }) => {
  const { superSubmit } = useContext(FuncionesContext);
  const [v, setStore] = useRefDataStore((s) => s[data._key + campokey]);
  const [update_id] = useRefDataStore(
    (s) => s[data._key + cab.update_id_alias]
  );

  const { opciones } = useContext(Context);

  const [value, setValue] = useState(v ?? valor);
  const [lastValue, setLastvalue] = useState(v);
  const [updateId, setUpdateId] = useState(update_id ?? updateid);

  const [error, setError] = useState(false);

  const nombre = (() => {
    if (cab.nombre_alias) {
      return data[cab.nombre_alias];
    }
    return cab.nombre;
  })();

  const handleCancelar = () => {
    setValue(v);
    const inputElement = document.getElementById(id_elemento + "_field");
    inputElement.value = v;
    // console.log(data[campokey], inputElement);
  };

  const handleOnBlur = (e) => {
    const valor = e.target.value;
    if (valor === lastValue || (valor === "" && lastValue === null)) {
      return;
    }
    if (valor === null || lastValue?.toString() === valor.toString().trim())
      return;
    if (validarRegex(e.target.value, cab.validacion_regex, cab)) {
      setError(false);
      return;
    }
    handleCancelar();
    //  setError(true);
    return;
  };

  const handleGuardar = async (e) => {
    const valor = e.target.value;
    if (valor === lastValue || (valor === "" && lastValue === null)) {
      return;
    }
    if (valor === null || lastValue?.toString() === valor.toString().trim())
      return;

    //  const update_id = data[cab.update_id_alias];
    const { id_a } = cab;

    superSubmit({ valor, id_a, update_id: updateId, handleCancelar, cab, data })
      .then((result) => {
        setLastvalue(() => valor);

        setLastvalue(() => valor);
        setStore({ [data._key + campokey]: valor });
        if (!updateId) {
          setStore({
            [data._key + cab.update_id_alias]: result.data.registro.id,
          });
          setUpdateId(result.data.registro.id);
        }
        return result;
      })
      .catch((err) => {
        console.log("Cancelado ", err);
        handleCancelar();
      });
  };

  const align = (() => {
    let clas = "align-left";

    if (cab.align?.trim() === "center") {
      clas = "align-center";
    }
    if (cab.align?.trim() === "right") {
      clas = "align-right";
    }

    return clas;
  })();

  const disabled = (() => {
    if (data[cab.id_a + "_disabled"]) {
      return data[cab.id_a + "_disabled"] === "s";
    }
    if (cab.disabled) {
      return cab.disabled === "s";
    }
    return false;
  })();

  return (
    <div id={id_elemento} className="tarjeta_grid_item_label_item">
      {opciones.tipo.id === 6 ? (
        <div className="vista_label">
          <p
            style={{
              maxHeight: "25px",
              position: "relative",
              top: "2px",
              wordBreak: "break-all",
            }}
            className="suW"
          >
            {nombre}:
          </p>
        </div>
      ) : (
        <></>
      )}
      <TextFieldEditable
        disabled={disabled}
        value={value}
        error={error}
        helperText={error ? cab.helperText : ""}
        lastValue={lastValue}
        setValue={setValue}
        onEnter={handleGuardar}
        onBlur={handleOnBlur}
        maxCaracteres={parseInt(cab.maximo_caracteres)}
        idInput={id_elemento + "_field"}
        className={`_textFieldEditable ${align}`}
      />
    </div>
  );
};

export default InputTextEditable;

import React, { useContext, useEffect } from "react";

import logo from "../../assets/img/brand/logo-orixia-gris.png";
import bgImage from "../../assets/images/bg-login.jpg";
import logoWhite from "../../assets/img/brand/logo-orixia-color.png";
import logoWhiteColegio from "../../assets/images/logoColegio.png";
import { ThemeContext } from "../../Theme";

const Theme = (props) => {
  let Theming = React.lazy(() => import("./orixiaMuiTheme"));

  const { dispatch } = useContext(ThemeContext);

  useEffect(() => {
    dispatch({
      type: "SET_LOGO",
      payload: { logo, bgImage, logoWhite, logoWhiteColegio },
    });
  }, [dispatch]);

  return <Theming>{props.children}</Theming>;
};

export default Theme;

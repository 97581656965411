import "react-app-polyfill/ie9"; // For IE 9-11 support
import "react-app-polyfill/stable";
// import 'react-app-polyfill/ie11'; // For IE 11 support
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { AlertasProvider } from "./views/Pantalla/context/AlertaContext";
import store from "./redux/store";
import Theme from "./Theme";
import "./App.scss";
import createFastContext from "./views/Pantalla/context/refContext";
import { initialState } from "./views/Pantalla/context/PantallaReducer";

export const { RefProvider, useRefStore } = createFastContext(initialState);
export const {
  RefProvider: RefDataProvider,
  useRefStore: useRefDataStore,
} = createFastContext({});

ReactDOM.render(
  <Provider store={store}>
    <Theme>
      <AlertasProvider>
        <RefProvider>
          <RefDataProvider>
            <App />
          </RefDataProvider>
        </RefProvider>
      </AlertasProvider>
    </Theme>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();

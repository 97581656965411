import React, { useContext, useMemo, useCallback } from "react";
import VistaContext from "../context/VistaContext";
import SwitchCampos from "./SwitchCampos";
import PantallaContext from "../../context/PantallaContext";
import { Card, CardBody } from "reactstrap";
import { agregarAcciones } from "../../helper/funciones";

import "./Tarjeta.scss";
import "./Vista.scss";
import HeaderConf from "../../components/HeaderConf";
import Boton from "./columnas/Boton";

const Tarjeta = ({ indiceConf }) => {
  const { datos, cabeceras, opciones, id, qsBody, vistaBotones } =
    useContext(VistaContext);
  const { opciones_de_pantalla } = useContext(PantallaContext);

  const styles = {
    gridColumn: opciones.grid_span ? opciones.grid_span : "1 / -1",
    border: "none",
    marginBottom: 0,
  };

  const gridTemplatecolumns = useCallback(() => {
    if (datos.length === 1) return "repeat(12, 1fr)";

    return "repeat(auto-fill, minmax(340px, 1fr)";
  }, [datos.length]);

  const gridcolumns = useCallback(() => {
    if (datos.length === 1) return "span 12";
    return undefined;
  }, [datos.length]);

  agregarAcciones(opciones_de_pantalla, opciones);

  return (
    <Card style={styles} id={opciones.id_a} className="animated fadeIn">
      <HeaderConf
        opciones={opciones}
        className="configuracion_pantalla_titulo_secundario"
      />
      <CardBody style={{ padding: 0 }}>
        {useMemo(
          () =>
            datos.length === 0 ? (
              <div>{opciones.vista_sql_vacio}</div>
            ) : (
              <div
                className="tarjeta"
                style={{
                  gridTemplateColumns: gridTemplatecolumns(),
                }}
              >
                {/* {datos.length === 0
            ? `(No se recuperaron Datos del id especificado) id: ${id}`
            : null} */}

                {datos.length === 0 ? (
                  <div
                    style={{
                      gridColumn: "1/-1",
                    }}
                    className={`tarjeta_grid_item ${opciones.className}`}
                  >
                    {cabeceras
                      .filter((cab) => cab.componente !== "hidden")
                      .map((cab, i) => {
                        return (
                          <SwitchCampos
                            data={{ nada: "nada" }}
                            cab={cab}
                            key={cab.id_a + i}
                            indiceData={0}
                            indiceConf={indiceConf}
                            Context={VistaContext}
                            id_elemento={cab.id_a + i}
                          />
                        );
                      })}
                  </div>
                ) : (
                  datos.map((dato, indiceData) => {
                    return (
                      <div
                        style={{
                          gridColumn: gridcolumns(),
                        }}
                        key={JSON.stringify(dato)}
                        className={`tarjeta_grid_item ${opciones.className}`}
                      >
                        {cabeceras
                          .concat(vistaBotones)
                          .filter((cab) => cab.componente !== "hidden")
                          .sort((a, b) => a.orden - b.orden)
                          .map((cab, i) => {
                            return (
                              <SwitchCampos
                                key={cab.id_a}
                                indiceData={indiceData}
                                indiceConf={indiceConf}
                                data={dato}
                                datos={datos}
                                cab={cab}
                                Context={VistaContext}
                                id_elemento={cab.id_a + indiceData}
                                id_global={id}
                                qsBody={qsBody}
                              />
                            );
                          })}
                      </div>
                    );
                  })
                )}
              </div>
            ),
          [
            cabeceras,
            datos,
            gridTemplatecolumns,
            gridcolumns,
            id,
            opciones.className,
            opciones.vista_sql_vacio,
            qsBody,
          ]
        )}
      </CardBody>
    </Card>
  );
};

export default Tarjeta;

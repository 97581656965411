import React from "react";
import Button from "@mui/material/Button";

import "./botonera.scss";

const Cancelar = ({ handleCancelar, size }) => {
  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        size={size ?? "medium"}
        onClick={handleCancelar}
        style={{ width: "100px", marginRight: "20px" }}
        sx={{ bgcolor: "primary.main" }}
        className="btn-cancelar"
      >
        Cancelar
      </Button>
    </>
  );
};

export default Cancelar;

import React, { useEffect } from "react";
import styles from "../helper/Style.module.css";

export const withTools = (Componente) => (props) => {
  const { data, cab, indiceData, id_elemento } = props;

  useEffect(() => {
    const g = document.getElementById(id_elemento); //?.parentNode;
    if (g) {
      if (g && g instanceof HTMLElement) {
        g.setAttribute("data-tooltip", true);
      }

      if (
        (cab.tooltip_texto && cab.tooltip_texto !== "") ||
        (data[cab.id_a + "_tooltip_texto"] &&
          data[cab.id_a + "_tooltip_texto"] !== "")
      ) {
        const id_tooltip = id_elemento + "_tooltip" + indiceData;

        const tooltip = document.createElement("span");
        tooltip.setAttribute("aria-hidden", "true");
        tooltip.id = id_tooltip;
        tooltip.appendChild(
          document.createTextNode(
            `${data[cab.id_a + "_tooltip_texto"] ?? cab.tooltip_texto}`
          )
        );

        tooltip.classList.add("Ftooltip"); // Pantalla.scss
        g.style.position = "relative";

        if (
          data[cab.id_a + "_tooltip_class"] === "verde" ||
          cab.tooltip_class === "verde"
        ) {
          tooltip.classList.add(styles.bg_verde);
          tooltip.classList.add("Ftooltip_verde");
        }

        if (
          data[cab.id_a + "_tooltip_class"] === "rojo" ||
          cab.tooltip_class === "rojo"
        ) {
          //tooltip.style.color = "black";
          tooltip.classList.add(styles.bg_rojo);
          tooltip.classList.add("Ftooltip_rojo");
        }

        if (data[cab.id_a + "_tooltip_class"]) {
          tooltip.classList.add(data[cab.id_a + "_tooltip_class"]);
        }

        const showTooltip = (e) => {
          tooltip.setAttribute("aria-hidden", "false");
          g.appendChild(tooltip);

          //  observer.observe(tooltip);
          const tooltipWidth = tooltip.offsetWidth;
          const tooltipHeight = tooltip.offsetHeight;

          tooltip.style.whiteSpace = "normal"; // Permite que las líneas se rompan
          tooltip.style.minWidth = "8em"; // Ancho mínimo de 16em
          tooltip.style.maxWidth = "calc(100vw - 20px)"; // Max-width para que no se salga de la pantalla
          tooltip.style.maxHeight = "16em"; // Max-width para que no se salga de la pantalla
          tooltip.style.wordWrap = "break-word"; // Fuerza a que las palabras largas se rompan

          const elementRect = g.getBoundingClientRect();
          const elementX = elementRect.x;
          const elementY = elementRect.y;

          let tooltipClientRect = tooltip.getBoundingClientRect();
          let tooltipX = elementX + elementRect.width / 2;
          let tooltipY =
            elementY - tooltipClientRect.height - 0.5 * g.offsetHeight;
          if (tooltipX < 0) {
            tooltipX = 0;
          } else if (tooltipX + tooltipWidth > window.innerWidth) {
            tooltipX = elementX - elementRect.width / 2 - 15; // - tooltipWidth * 0.25;
          }

          if (tooltipY < 0) {
            tooltipY = 0;
          } else if (tooltipY + tooltipHeight > window.innerHeight) {
            //  tooltipY = window.innerHeight - tooltipHeight;
          }

          tooltip.style.left = `${tooltipX}px`;
          tooltip.style.top = `${tooltipY}px`;
        };

        const removeTooltip = (e) => {
          const t = document.getElementById(id_tooltip);
          tooltip.setAttribute("aria-hidden", "true");
          //  observer.unobserve(t);

          if (t) {
           t.remove();
          }
        };

        g.addEventListener("mouseenter", (e) => {
          e.stopPropagation();
          showTooltip(e);
        });

        g.addEventListener("mouseleave", (e) => {
          e.stopPropagation();
          removeTooltip(e);
        });
        window.addEventListener("scroll", removeTooltip);
      }
    }
  }, [props]);

  return (
    <>
      <Componente {...props} />
    </>
  );
};

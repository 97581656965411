import React, { useRef, useEffect } from "react";
import { TextField } from "@mui/material";

const TextFieldEditable = ({
  value,
  lastValue,
  setValue,
  onEnter,
  maxCaracteres,
  className,
  disabled,
  id,
  idInput,
  error,
  helperText,
  onBlur,
}) => {
  const keyUp = useRef(0); // Evita que el evento onBlur se dispare luego de onKeyUp
  const altEnter = useRef(0); // Detecta keyDown = alt + enter
  const setingStart = useRef();
  const firstRender = useRef(0);

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      if (altEnter.current !== 0) return;

      keyUp.current = 1;
      e.target.blur();

      return onEnter(e).then(() => {
        keyUp.current = 0;
      });
    }
    return (altEnter.current = 0);
  };

  const handleOnBlur = (e) => {
    onBlur(e);
    if (keyUp.current !== 0) return;
    return onEnter(e);
  };

  const handleInput = (e) => {
    const valor = e.target.value;
    setValue(valor);
  };

  const handleAlt = (e) => {
    setingStart.current = e.target.selectionStart;

    if (e.key === "Enter") {
      e.preventDefault();
    }
    if (e.key === "Enter" && e.altKey) {
      altEnter.current = 1;

      setValue(
        value.slice(0, setingStart.current) +
          "\n" +
          value.slice(setingStart.current, value.length)
      );
      return;
    }
    if (e.key === "Backspace") {
      setingStart.current = setingStart.current - 2;
    }
    return;
  };

  useEffect(
    (d) => {
      if (firstRender.current === 0) {
        firstRender.current = 1;
        return;
      }
      const ctrl = document.getElementById(id);

      if (ctrl?.setSelectionRange) {
        ctrl.focus();
        ctrl.setSelectionRange(
          setingStart.current + 1,
          setingStart.current + 1
        );
      }
    },
    [value]
  );

  return (
    <TextField
      id={id}
      value={value}
      onChange={handleInput}
      onKeyUp={handleEnter}
      onBlur={handleOnBlur}
      onKeyDown={handleAlt}
      style={{ width: "100%" }}
      inputProps={{ id: idInput, maxLength: maxCaracteres ?? 60 }}
      multiline
      className={`  ${className} textField`}
      size="small"
      disabled={disabled}
      error={error}
      helperText={helperText}
    />
  );
};

export default TextFieldEditable;

import React, { useEffect, useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhotoFilm } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom/cjs/react-router-dom";
import ListadoContext from "../../Listado/context/ListadoContext";
import { v4 } from "uuid";

const AbrirCarrusel = ({ opciones }) => {
  const { actualizarListado } = useContext(ListadoContext);
  const [id, setId] = useState("");

  useEffect(() => {
    const id = v4();
    setId(id);
  }, []);

  const handleClick = async (event) => {
    event.preventDefault();

    try {
      // Realiza la fetch de datos
      const {
        data: { datos, cabeceras, opciones },
      } = await actualizarListado();

      localStorage.setItem(`datos_${id}`, JSON.stringify(datos));
      localStorage.setItem(`cabeceras${id}`, JSON.stringify(cabeceras));
      localStorage.setItem(`opciones_${id}`, JSON.stringify(opciones));

      const url = new URL(
        "#/viendoimagenes" + `?uselc=s&i=${id}`,
        window.location.origin
      );
      window.open(url, "_blank");
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  return (
    <Link
      to={{ pathname: "/viendoimagenes", search: `?uselc=s&i=${id}` }}
      target="_blank"
      onClick={handleClick}
    >
      <div
        className={
          opciones.titulo
            ? "flex_acciones_vista_email"
            : "flex_acciones_vista_alt_email"
        }
        style={{ cursor: "pointer" }}
      >
        <FontAwesomeIcon icon={faPhotoFilm} />
      </div>
    </Link>
  );
};

export default AbrirCarrusel;

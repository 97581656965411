import React from "react";
import { fecha } from "../../../context/FuncionesContext";

const FechaC = ({ data, cab, hijos, campokey, indiceData, id_elemento }) => {
  try {
    const className = data[cab.id_a + "_className"] ?? cab.className;

    return (
      <div
        id={id_elemento}
        className={className}
        style={{ textAlign: "center" }}
      >
        {data[campokey] ? fecha(data, cab, campokey) : null}
        {hijos}
      </div>
    );
  } catch (err) {
    console.log(data[campokey], err);
    return <>{data[campokey]}</>;
  }
};

export default FechaC;

import React, { useState, useContext, useEffect, useRef } from "react";
import Select from "../../../components/Select";
import FuncionesContext from "../../../context/FuncionesContext";
import ListadoContext from "../../context/ListadoContext";
import Axios from "axios";
import { farmageo_api } from "../../../../../config";

const SelectC = ({ data, cab, hijos, campokey, indiceData, id_elemento }) => {
  const { select_es_maestro, select_depende_de, select_maestro_de, id_a } = cab;

  const funciones = useContext(FuncionesContext);
  const { superSubmit } = funciones;

  const { ListadoDispatch, Dispatch, celdas } = useContext(ListadoContext);
  // const { configuraciones_ref, PantallaDispatch } = useContext(PantallaContext);

  const disabled = (() => {
    if (data[cab.id_a + "_disabled"]) {
      return data[cab.id_a + "_disabled"] === "s";
    }
    if (cab.disabled) {
      return cab.disabled === "s";
    }
    return false;
  })();
  
  const componerOpciones = () => {
    const value_null =
      data[cab.id_a + "_select_value_null"] ?? cab.select_value_null;
    const label_null =
      data[cab.id_a + "_select_label_null"] ?? cab.select_label_null;
    const opcion_null = {
      value: value_null ? value_null : null,
      label: label_null ? label_null : "Ninguno",
      disabled: disabled === "true",
    };
    if (Array.isArray(cab.opciones)) return cab.opciones;
    if (data[cab.id_a + "_opciones"]) {
      const opciones_de_query_general = JSON.parse(
        data[cab.id_a + "_opciones"]
      );

      if (Array.isArray(opciones_de_query_general)) {
        if (value_null || label_null)
          opciones_de_query_general.push(opcion_null);
        return opciones_de_query_general;
      }
    }
    if (value_null || label_null) {
      return [opcion_null];
    }
    return [];
  };

  const [value, setValue] = useState();
  const [lastValue, setLastvalue] = useState(data[campokey]);
  const [loading, setLoading] = useState(true);
  const celdaref = useRef(celdas[id_a + data._key]);
  const [opciones, setOpciones] = useState(componerOpciones());

  const nombre = (() => {
    if (cab.nombre_alias) {
      return data[cab.nombre_alias];
    }
    return cab.nombre;
  })();

  const handleCancelar = () => {
    setValue(data[campokey]);
    setLastvalue(data[campokey]);
  };

  const handleChange = async (e) => {
    const valor = funciones.transformarTipoDato(e.target.value, typeof value);

    const update_id = data[cab.update_id_alias];

    if (valor === null || lastValue?.toString() === valor.toString().trim())
      return;

    setValue(valor);

    await superSubmit({ valor, id_a, update_id, handleCancelar, cab, data })
      .then((result) => {
        setLastvalue(valor);
        ListadoDispatch({
          type: "SET_DATO_ESPECIFICO",
          payload: {
            key: cab.update_id_alias, // update_id
            indiceData: indiceData, // 4
            value: result.data.registro.id,
          },
        });

        ListadoDispatch({
          type: "SET_DATO_ESPECIFICO",
          payload: {
            key: cab.id_a, // update_id
            indiceData: indiceData, // 4
            value: valor,
          },
        });

        if (cab.efecto_funcion && cab.efecto_funcion.trim() !== "") {
          if (
            (cab.efecto_valor && cab.efecto_valor == valor) ||
            !cab.efecto_valor
          ) {
            const funcion =
              data[cab.id_a + "_efecto_funcion"] ?? cab.efecto_funcion;
            const datosActuales = { ...data, [cab.id_a]: valor };

            funciones[funcion]({
              data: datosActuales,
              cab,
              indiceData,
              handleCancelar,
              dispatch: Dispatch,
            });
          }
        }
      })
      .catch((err) => {
        console.log("Cancelado ", err);
        handleCancelar();
      });

    if (
      select_es_maestro === "s" &&
      select_maestro_de &&
      select_maestro_de.trim() !== ""
    ) {
      select_maestro_de.split(",").forEach((ida) => {
        Dispatch({
          type: "REFRESCAR_CELDA",
          payload: {
            id: ida.trim() + data._key,
            ref: funciones.refrescarConfiguracion,
          },
        });
      });
      return;
    }
  };

  useEffect(() => {
    setValue(data[campokey]);
  }, []);

  useEffect(() => {
    if (!celdas[id_a + data._key] || !celdaref.current) return;
    if (celdas[id_a + data._key] !== celdaref.current) {
      setLoading(() => true);
      celdaref.current = celdas[id_a + data._key];
      Axios.post(farmageo_api + "/select/opciones", {
        [id_a]: data[select_depende_de],
      }).then(async (res) => {
        ListadoDispatch({
          type: "SET_DATO_ESPECIFICO",
          payload: {
            key: cab.id_a + "_opciones", // update_id
            indiceData: indiceData, // 4
            value: JSON.stringify(res.data),
          },
        });
        ListadoDispatch({
          type: "SET_DATO_ESPECIFICO",
          payload: {
            key: cab.id_a, // update_id
            indiceData: indiceData, // 4
            value: cab.default,
          },
        });
        setLoading(() => false);
      });
    }
  }, [celdas[id_a + data._key]]);

  return (
    <div id={id_elemento} style={{ textAlign: "center" }}>
      <Select
        nombre={nombre}
        opciones={opciones ?? []}
        value={value}
        onChange={handleChange}
        def={cab.default}
        disabled={disabled}
        className={data[cab.id_a + "_className"] ?? cab.className}
      />
    </div>
  );
};

export default SelectC;

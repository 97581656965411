import React from "react";
import Imprimir from "./Imprimir";
import Email from "./Email";
import Historial from "./Historial";
import Excel from "./Excel";
import Configuracion from "./Configuracion";
import Ayuda from "./Ayuda";
import AbrirCarrusel from "./AbrirCarrusel";

const AccionesVista = ({ opciones, className }) => {
  if (
    (!opciones.imprimir_activo || opciones.imprimir_activo === "n") &&
    (!opciones.email_activo || opciones.email_activo === "n") &&
    (!opciones.historial_activo || opciones.historial_activo === "n") &&
    (!opciones.configuracion_usuario_activo ||
      opciones.configuracion_usuario_activo === "n") &&
    (!opciones.excel_activo || opciones.excel_activo === "n") &&
    (!opciones.ayuda_activa || opciones.ayuda_activa === "n") &&
    (!opciones.usar_carrusel || opciones.usar_carrusel === "n")
  ) {
    return <></>;
  }

  return (
    <div
      id={opciones.id_a + "_menu_vista"}
      className={`menu_vista ` + className}
    >
      <div className="flex_acciones_vista">
        {opciones.imprimir_activo === "s" ? (
          <Imprimir opciones={opciones} />
        ) : (
          <></>
        )}
        {opciones.email_activo === "s" ? <Email opciones={opciones} /> : <></>}
        {opciones.historial_activo === "s" ? (
          <Historial opciones={opciones} />
        ) : (
          <></>
        )}
        {opciones.tipo.id !== 1 && opciones.excel_activo === "s" ? (
          <Excel opciones={opciones} />
        ) : (
          <></>
        )}
        {opciones.configuracion_usuario_activo === "s" ? (
          <Configuracion opciones={opciones} />
        ) : (
          <></>
        )}
        {opciones.ayuda_activa === "s" ? <Ayuda opciones={opciones} /> : <></>}
        {opciones.usar_carrusel === "s" ? (
          <AbrirCarrusel opciones={opciones} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default AccionesVista;

import React from "react";
import { Editor } from "primereact/editor";
import { Box } from "@mui/material";
import styled from "styled-components";

// Estilos personalizados utilizando styled-components
const StyledEditor = styled(Editor)`
  .p-editor-toolbar {
    display: none;
    width: 100%;
  }
  .ql-container.ql-snow {
    border: 1px solid #fff0;
  }
  .p-editor-content {
    width: 100%;
  }
`;

export const TextArea = ({ data, cab, campokey, id_elemento, valor }) => {
  let classNames = data[cab.id_a + "_className"] ?? cab.className;

  return (
    <Box sx={{ width: "100%" }} id={id_elemento}>
      <StyledEditor
        className={classNames}
        id={id_elemento}
        value={valor ?? data[campokey]}
        disabled
        readOnly
      />
    </Box>
  );
};

import React from "react";

const SiNo = ({ data, campokey, indiceData, cab, id_elemento }) => {
  const className = data[cab.id_a + "_className"] ?? cab.class

  return (
    <div
      id={id_elemento}
      style={{ textAlign: "center" }}
      className={className}
    >
      {data[campokey] === "s" ? "SI" : "NO"}
    </div>
  );
};

export default SiNo;

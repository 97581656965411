import React, { useState, useContext, useEffect } from "react";
import FuncionesContext from "../../../context/FuncionesContext";
import PantallaContext from "../../../context/PantallaContext";

import { useRefDataStore } from "../../../../..";

const SiNoEditable = React.memo(({ data, cab, campokey, id_elemento }) => {
  const { superSubmit } = useContext(FuncionesContext);

  const [v, setStore] = useRefDataStore((s) => s[data._key + campokey]);
  const [updateId] = useRefDataStore((s) => s[data._key + cab.update_id_alias]);

  const { configuraciones_ref } = useContext(PantallaContext);
  //  const { datos, VistaDispatch } = useContext(VistaContext);
  const [value, setValue] = useState(v);
  const [lastValue, setLastvalue] = useState(v);
  // const [update_id, setUpdate_id] = useState(updateId);

  const nombre = (() => {
    if (cab.nombre_alias) {
      return data[cab.nombre_alias];
    }
    return cab.nombre;
  })();

  const handleCancelar = () => {
    setValue(v);
    setLastvalue(v);
  };

  const handleChange = async (e) => {
    const valor = e.target.value;

    if (valor === null || lastValue?.toString() === valor.toString().trim())
      return;

    const { id_a } = cab;
    setValue(valor);

    superSubmit({
      valor,
      id_a,
      update_id: updateId,
      handleCancelar,
      cab,
      data,
    }).then((result) => {
      setLastvalue(valor);
      setStore({ [data._key + campokey]: valor });
      if (!updateId) {
        setStore({
          [data._key + cab.update_id_alias]: result.data.registro.id,
        });
      }
    });
  };

  useEffect(() => {
    setValue(v);
    // setUpdate_id(updateId);
  }, [configuraciones_ref[cab.id_a]]);

  useEffect(() => {
    setValue(v);
  }, [v]);

  // console.log("si_no refresh", data._key);

  return (
    <div id={id_elemento} className="tarjeta_grid_item_label_item">
      {nombre ? (
        <div className="vista_label vista_label_fuente">{nombre}:</div>
      ) : (
        <></>
      )}
      <select
        onChange={handleChange}
        value={value === null ? "null" : value === "s" ? "s" : "n"}
        style={{ marginLeft: "3px", marginRight: "3px" }}
        className={data[cab.className] ?? cab.className}
      >
        <option value="s" disabled={updateId ? false : true}>
          SI
        </option>
        <option value="n" disabled={updateId ? false : true}>
          NO
        </option>
        <option value="null" disabled></option>
      </select>
    </div>
  );
});

//actulizo este componente y va a recibir el id_update correcto
//o le tendría que pasar el update_id

export default SiNoEditable;

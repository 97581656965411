import React from "react";
import { Button } from "@mui/material";
import "../../botonera.scss";

const Buscar = ({ handleSubmit, loading }) => {
  return (
    <>
      <Button
        variant="contained"
        size="small"
        onClick={handleSubmit}
        disabled={loading}
        color="primary"
        sx={{ bgcolor: "primary.main", color: "rgb(255,255,255) !important" }}
        className="button_buscar"
      >
        Buscar
      </Button>
    </>
  );
};

export default Buscar;

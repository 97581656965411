import React from "react";
import { Button } from "@mui/material";

import "./botonera.scss";

const Guardar = ({ handleSubmit, loading, id, texto_confirmar, size }) => {
  return (
    <>
      <Button
        id="abm_boton_guardar"
        variant="contained"
        color={id ? "primary" : "secondary"}
        size={size ?? "medium"}
        onClick={handleSubmit}
        disabled={loading}
        style={{ width: "100px", marginRight: "20px" }}
        className="btn-guardar"
      >
        {texto_confirmar ?? (id ? "Guardar" : "Crear")}
      </Button>
    </>
  );
};

export default Guardar;

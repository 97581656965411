import React from "react";

//****************** CONFIGURACIONES ************************************/
const Pantalla = React.lazy(() => import("./views/Pantalla/Pantalla"));
const Dashboard = React.lazy(() => import("./views/Dashboard/Dashboard"));
const Login = React.lazy(()=>import("./views/Pages/Login/Login"))

const routes = [
  { path: "/", exact: true, name: "" },
  {
    path: "/login",
    exact: true,
    name: "Inicio",
    component: Login,
    r:true  // No es una ruta del sistema. Es solo para levantar el componente Artesanal.
  },
  {
    path: "/dashboard",
    exact: true,
    name: "Inicio",
    component: Dashboard,
    r:true
  },
  {
    path: "/Pantalla/:pantalla",
    exact: true,
    name: "Pantalla",
    component: Pantalla,
    permiso: "inicio",
  },
  {
    path: "/Listado/:pantalla",
    exact: true,
    name: "Listado",
    component: Pantalla,
    permiso: "inicio",
  },
  {
    path: "/Vista/:pantalla",
    exact: true,
    name: "Vista",
    component: Pantalla,
    permiso: "inicio",
  },
  {
    path: "/Configuracion/:pantalla",
    exact: true,
    name: "Configuracion",
    component: Pantalla,
    permiso: "inicio",
  },

];

export default routes;

import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import FuncionesContext from "../../context/FuncionesContext";

const Ayuda = ({ opciones }) => {
  const { enviarAModal } = useContext(FuncionesContext);

  const handleClick = () => {
    enviarAModal(
      opciones.ayuda_id_a,
      {},
      {
        min_width_modal: opciones.min_width_modal,
      }
    );
  };
  return (
    <div
      className={
        opciones.titulo
          ? "flex_acciones_vista_email"
          : "flex_acciones_vista_alt_email"
      }
      style={{ cursor: "pointer" }}
      onClick={handleClick}
    >
      <FontAwesomeIcon icon={faQuestionCircle} />
    </div>
  );
};

export default Ayuda;

import React, { useState, useContext, useEffect } from "react";
import FuncionesContext from "../context/FuncionesContext";
import TextArea from "./TextArea";
import TextField from "@mui/material/TextField";
import { useRefDataStore } from "../../..";

const InputAreaEditable = ({ data, cab, campokey, type, id_elemento, valor, updateid }) => {
  const { superSubmit, validarRegex } = useContext(FuncionesContext);

  const [v, setStore] = useRefDataStore((s) => s[data._key + campokey]);
  const [update_id] = useRefDataStore(
    (s) => s[data._key + cab.update_id_alias]
  );
  // const update_id = _data[cab.update_id_alias];
  const [value, setValue] = useState(v ?? valor);
  const [lastValue, setLastvalue] = useState(v);
  const [updateId, setUpdateId] = useState(update_id ?? updateid);

  const nombre = (() => {
    if (cab.nombre_alias) {
      return data[cab.nombre_alias];
    }
    return cab.nombre;
  })();

  const disabled = data[cab.id_a + "_disabled"]
    ? data[cab.id_a + "_disabled"] === "s"
    : cab.disabled === "s";

  let classNames = data[cab.id_a + "_className"] ?? cab.className;

  const handleCancelar = () => {
    setValue(lastValue);
    const inputElement = document.getElementById(id_elemento + "_input");
    inputElement.value = lastValue;
  };

  const handleGuardar = async (e) => {
    const valor = e.target.value;
    if (valor === lastValue || (valor === "" && lastValue === null)) {
      return;
    }
    if (valor === null || lastValue?.toString() === valor.toString().trim())
      return;

    if (!validarRegex(valor, cab.validacion_regex, cab)) {
      handleCancelar();
      return;
    }

    const { id_a } = cab;

    superSubmit({ valor, id_a, update_id: updateId, handleCancelar, cab, data })
      .then((result) => {
        setLastvalue(() => valor);
        setStore({ [data._key + campokey]: valor });
        if (!updateId) {
          setStore({
            [data._key + cab.update_id_alias]: result.data.registro.id,
          });
          setUpdateId(result.data.registro.id);
        }
        return result;
      })
      .catch((err) => {
        handleCancelar();
        console.log("Cancelado ", err);
      });
  };

  (() => {
    if (updateId) {
      classNames = `${classNames} 
        ${data[cab.id_a + "_update_className"] ?? cab.update_className}`;
      return;
    }
    classNames = classNames + " sinUpdateId";
    return;
  })();

  /// esto es para que cuando la vista haga el fetch de datos, actualizar el dato que se muestra.
  useEffect(() => {
    setValue(v);
    setUpdateId(update_id);
  }, [v, update_id]);

  return (
    <div
      style={{ width: "100%" }}
      id={id_elemento}
      className="tarjeta_grid_item_label_item"
    >
      {nombre ? <div className="vista_label">{nombre}:</div> : <></>}
      {type === "number" ? (
        classNames?.includes("native") ? (
          <input
            id={id_elemento + "_input"}
            type="number"
            onBlur={handleGuardar}
            defaultValue={value}
            style={{
              textAlign: "right",
              fontSize: "1em",
              width: cab.width_input,
            }}
            disabled={disabled}
            className={classNames}
          />
        ) : (
          <TextField
            id={cab.id_a}
            type="number"
            onBlur={handleGuardar}
            defaultValue={value}
            inputProps={{
              id: id_elemento + "_input",
              style: {
                textAlign: cab.align ?? "right",
                display: "flex",
                alignItems: "baseline",
                width: cab.width_input,
              },
            }}
            disabled={disabled}
            className={
              disabled ? classNames + " textField_disabled" : classNames
            }
            variant="outlined"
          />
        )
      ) : (
        <TextArea
          disabled={disabled}
          className={disabled ? classNames + " textArea_disabled" : classNames}
          value={value}
          setValue={setValue}
          onEnter={handleGuardar}
          style={{
            width: cab.width_input,
          }}
          id={id_elemento + "_input"}
        />
      )}
    </div>
  );
};

export default InputAreaEditable;

import React from "react";
import Titulo from "./Titulo";
import AccionesVista from "./Menu/AccionesVista";
import { CardTitle } from "reactstrap";

const HeaderConf = ({ opciones, className }) => {
  if (!opciones || !opciones.tipo) return <></>;
  const classNoTitulo = opciones.tipo.id === 2 ? "menu_listado_no_titulo" : "menu_vista_no_titulo"
  return (
    <CardTitle
      id={opciones.id_a + "_HEADERCONF"}
      className="tarjeta_titulo"
      style={{ position: "relative", margin: "0" }}
    >
      {opciones.titulo ? (
        <>
          <Titulo
            tag={opciones.titulo_tag}
            titulo={opciones.titulo}
            className={className}
          />
        </>
      ) : (
        <></>
      )}

      {opciones.tipo?.id === 1 && opciones.titulo ? (
        <>
          {" "}
          <AccionesVista
            opciones={opciones}
            className={` ${
              !opciones.titulo || opciones.titulo === ""
                ? classNoTitulo
                : "menu_vista_no_listado"
            }`}
          />
        </>
      ) : (
        <AccionesVista
          opciones={opciones}
          className={` ${
            !opciones.titulo || opciones.titulo === ""
              ? classNoTitulo
              : "menu_vista_no_listado"
          }`}
        />
      )}
    </CardTitle>
  );
};

export default HeaderConf;

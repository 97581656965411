import React, { useState, useContext } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ar from "date-fns/locale/es";
import FuncionesContext from "../../../context/FuncionesContext";
import { parseISOString } from "../../../context/FuncionesContext";
import { useRefDataStore } from "../../../../..";
registerLocale("ar", ar);

const InputDate = ({
  data,
  cab,
  campokey,
  indiceData,
  id_elemento,
  Context,
  valor,
  updateid,
}) => {
  const { superSubmit } = useContext(FuncionesContext);
  const { opciones } = useContext(Context);

  const [v, setStore] = useRefDataStore((s) => s[data._key + campokey]);
  const [update_id] = useRefDataStore(
    (s) => s[data._key + cab.update_id_alias]
  );

  const [value, setValue] = useState(parseISOString(v ?? valor));
  const [lastValue, setLastvalue] = useState(parseISOString(v));
  const [updateId, setUpdateId] = useState(update_id ?? updateid);

  const permite_null =
    data[cab.id_a + "_permite_null"] === "s" || cab.permite_null === "s";
  const disabled = data[cab.id_a + "_disabled"] === "s" || cab.disabled === "s";

  const nombre = (() => {
    if (opciones.tipo.id === 2) return false;
    if (cab.nombre_alias) {
      return data[cab.nombre_alias];
    }
    return cab.nombre;
  })();

  const className = data[cab.id_a + "_className"] ?? cab.className;

  const handleCancelar = () => {
    setValue(parseISOString(v));
    setLastvalue(parseISOString(v));
  };

  const handleGuardar = async (date) => {
    const valor = date;
    if (value?.toDateString() === valor?.toDateString()) return;
    setValue(valor);
    if (!permite_null) {
      if (valor === null) return;
    }

    const valorParseado =
      valor === null
        ? null
        : valor.toISOString().replace(/T/, " ").replace(/\..+/, "");

    const { id_a } = cab;

    await superSubmit({
      valor: valorParseado,
      id_a,
      update_id: updateId,
      handleCancelar,
      cab,
      data,
      indiceData,
    })
      .then((result) => {
        setLastvalue(value);
        const parsedValor = valor === null ? null : valor.toISOString();
        setStore({ [data._key + campokey]: parsedValor });
        if (!updateId) {
          setStore({
            [data._key + cab.update_id_alias]: result.data.registro.id,
          });
          setUpdateId(result.data.registro.id);
        }

        return result;
      })
      .catch((err) => {
        console.log("Cancelado ", err);
      });
  };

  return (
    <div id={id_elemento} className="tarjeta_grid_item_label_item">
      {nombre ? <label className="vista_label">{nombre}:</label> : <></>}
      <DatePicker
        selected={value}
        onChange={handleGuardar}
        disabled={disabled}
        //isClearable={permite_null}
        dateFormat={cab.fecha_formato ?? "dd-MM-yyyy"}
        locale="ar"
        placeholderText=" sin fecha "
        withPortal={opciones.tipo.id === 2}
        showYearDropdown
        className={`input_fecha ${className}`}
      />
      {permite_null ? (
        <div
          onClick={() => handleGuardar(null)}
          className="columna_input_date_null"
        >
          x
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default InputDate;

import React from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import FuncionesContext from "../../../context/FuncionesContext";

const Enlace = ({ data, cab, hijos, campokey, indiceData, id_elemento }) => {
  const { enviarAModal } = useContext(FuncionesContext);

  const e = cab.enlace
    ? cab.enlace_alias
      ? data[cab.enlace_alias]
        ? data[cab.enlace_alias]
        : cab.enlace
      : cab.enlace
    : "";

  const parametros_valores = cab.enlace_parametros_alias
    ? data[cab.enlace_parametros_alias]
      ? data[cab.enlace_parametros_alias]?.toString().split(",")
      : cab.enlace_parametros?.toString().split(",")
    : undefined;

  const en = cab.enlace_parametros_nombres_alias
    ? data[cab.enlace_parametros_nombres_alias]
      ? data[cab.enlace_parametros_nombres_alias]?.toString().split(",")
      : cab.enlace_parametros_nombres?.toString().split(",")
    : cab.enlace_parametros_nombres?.toString().split(",");

  const id_a = data[cab.enlace_id_a_alias]
    ? data[cab.enlace_id_a_alias]
    : cab.enlace_id_a
    ? cab.enlace_id_a
    : "";

  let parametros = "";
  let paramObj = {};
  if (parametros_valores && en) {
    parametros = "?";
    parametros_valores.forEach((qP, i) => {
      parametros = parametros.concat("&" + en[i] + "=" + qP);
      paramObj[en[i]] = qP;
    });
  }

  const enlace =
    cab.enlace_externo === "s"
      ? e + parametros
      : process.env.PUBLIC_URL + "/#" + e + id_a + parametros;


  if (cab.target === "modal") {
    return (
      <div
        onClick={() =>
          enviarAModal(cab.enlace_id_a, paramObj, {
            min_width_modal: cab.min_width_modal,
          })
        }
        id={id_elemento}
        className="Listado_Switch_Enlace"
      >
        {cab.imagen_url ? (
          <img
            style={{ cursor: "pointer" }}
            height={"40px"}
            src={cab.imagen_url}
            alt="imagen"
          />
        ) : (
          <>
            {cab.boton_texto_alias
              ? data[cab.boton_texto_alias]
              : cab.boton_texto}
          </>
        )}
      </div>
    );
  }

  if (cab.target === "_blank") {
    return (
      <div id={id_elemento} className="Listado_Switch_Enlace">
        <Ancla
          cab={cab}
          enlace={enlace}
          data={data}
          hijos={hijos}
          target={cab.target}
        />
      </div>
    );
  }

  if (cab.target === "descarga") {
    const archivo_nombre =
      data[cab.id_a + "_archivo_nombre"] ??
      cab.archivo_nombre ??
      e.split("/").pop();
    return (
      <div
        id={id_elemento}
        className={`Listado_Switch_Enlace ${data[cab.id_a + "_className"]}`}
        style={{ width: "min-content" }}
      >
        <a
          id={id_elemento + "_a"}
          href={e}
          rel="noopener noreferrer"
          download={archivo_nombre}
        >
          <div
            style={{
              textAlign: cab.align ?? "center",
            }}
          >
            {cab.imagen_url ? (
              <img
                style={{ cursor: "pointer" }}
                height={"40px"}
                src={cab.imagen_url}
                alt="imagen"
              />
            ) : (
              <>
                {cab.boton_texto_alias
                  ? data[cab.boton_texto_alias]
                  : cab.boton_texto}
              </>
            )}

            {hijos}
          </div>
        </a>
      </div>
    );
  }

  return (
    <div id={id_elemento} className="Listado_Switch_Enlace">
      {cab.enlace_externo === "s" ? (
        <Ancla
          cab={cab}
          enlace={enlace}
          data={data}
          hijos={hijos}
          target="_self"
        />
      ) : (
        <Link
          to={{
            pathname: `${e + id_a}`,
            search: parametros,
            target: cab.target,
          }}
        >
          <div
            style={{
              textAlign: "center",
            }}
          >
            {cab.imagen_url ? (
              <img
                style={{ cursor: "pointer" }}
                height={"40px"}
                src={cab.imagen_url}
                alt="imagen"
              />
            ) : (
              <>
                {cab.boton_texto_alias
                  ? data[cab.boton_texto_alias]
                  : cab.boton_texto}
              </>
            )}

            {hijos}
          </div>
        </Link>
      )}
    </div>
  );
};

export default Enlace;

const Ancla = ({ cab, enlace, data, hijos, target }) => {
  return (
    <a target={target} href={enlace} rel="noopener noreferrer">
      <div
        style={{
          textAlign: cab.align ?? "left",
        }}
      >
        {cab.imagen_url ? (
          <img
            style={{ cursor: "pointer" }}
            height={"40px"}
            src={cab.imagen_url}
            alt="imagen"
          />
        ) : (
          <>
            {cab.boton_texto_alias
              ? data[cab.boton_texto_alias]
              : cab.boton_texto}
          </>
        )}

        {hijos}
      </div>
    </a>
  );
};

import React, {
  useEffect,
  useReducer,
  useContext,
  useState,
  // useRef,
} from "react";
import VistaContext from "./context/VistaContext";
import PantallaContext from "../context/PantallaContext";
import FuncionesContext from "../context/FuncionesContext";
import VistaReducer, { initialState } from "./context/VistaReducer";
import Tarjeta from "./components/Tarjeta";
import { useCallback } from "react";
import { useMemo } from "react";
import { useRefStore, useRefDataStore } from "../../..";
import { addsql, addData } from "../context/PantallaReducer";

const VistaProvider = ({
  configuracion,
  id,
  children,
  nollamar,
  qsBody,
  indiceConf,
}) => {
  const [store, setStore] = useRefStore();
  const [data, setDataStore] = useRefDataStore();

  const {
    configuraciones_ref,
    loadingPantalla,
    // PantallaDispatch,
    configuraciones_padre,
  } = useContext(PantallaContext);

  const { getConfiguracion, requestErrorHandler } =
    useContext(FuncionesContext);

  const [state, dispatch] = useReducer(VistaReducer, initialState);
  const [loading, setLoading] = useState(false);
  const id_a = configuracion.opciones.id_a;
  // Filtro de vista?
  const [filtros, setFiltros] = useState({
    pantalla: configuracion.opciones.id_a,
  });
  // const filtrosp = useRef({ pantalla: configuracion.opciones.id_a });

  const setFiltro = useCallback(
    async ({ id_a, valor }) => {
      try {
        if (filtros.current) {
          if (!valor || valor === "") {
            // setFiltros
            filtros.current = (() => {
              let ns = { ...filtros.current };
              delete ns[id_a];

              return ns;
            })();
          }

          // setFiltros
          filtros.current = (() => {
            let ns = { ...filtros.current };
            ns[id_a] = valor;

            return ns;
          })();

          return filtros.current;
        }

        if (!valor || valor === "") {
          setFiltros((f) => {
            let ns = { ...f };
            delete ns[id_a];
            return ns;
          });
        }

        setFiltros((f) => {
          // let ns = { ...f };
          // ns[id_a] = valor;
          return { ...f, [id_a]: valor };
        });
        return filtros;
      } catch (err) {
        console.log(err);
      }
    },
    [filtros]
  );

  useEffect(() => {
  
    dispatch({
      type: "SET_CABECERAS",
      payload: Object.assign(
        configuracion.cabeceras,
        configuracion.vistaBotones
      ),
    });
    dispatch({
      type: "SET_VISTA_BOTONES",
      payload: configuracion.vistaBotones,
    });
    dispatch({
      type: "SET_DATOS",
      payload: configuracion.datos,
    });

    dispatch({
      type: "SET_OPCIONES",
      payload: configuracion.opciones,
    });

    // refContext

    setDataStore(addData(configuracion));
  }, [
    configuracion,
    configuracion.cabeceras,
    configuracion.datos,
    configuracion.opciones,
    configuracion.vistaBotones,
    loadingPantalla,
    setDataStore,
  ]);

  useEffect(() => {
    //useEffect para actualizar datos a control

    if (configuraciones_ref[id_a] === 1) return;

    if (nollamar) return;

    (async () => {
      setLoading(true);

      await getConfiguracion(
        id_a,
        qsBody,
        filtros,
        configuraciones_padre[id_a]
      ).then((res) => {
        // PantallaDispatch({
        //   type: "ADD_SQL",
        //   payload: res.data?.sql,
        // });
        // console.log(res.data.sql)
        setStore({ sql: addsql(res.data?.sql, store.sql) });

        if (res.status >= 400) {
          setLoading(false);
          return requestErrorHandler(res);
        }
        dispatch({
          type: "SET_CABECERAS",
          payload: Object.assign(
            res.data.cabeceras,
            configuracion.vistaBotones
          ),
        });
        dispatch({
          type: "SET_VISTA_BOTONES",
          payload: configuracion.vistaBotones,
        });
        dispatch({
          type: "SET_DATOS",
          payload: res.data.datos,
        });

        setDataStore(addData({ datos: res.data.datos }));

        dispatch({
          type: "SET_OPCIONES",
          payload: res.data.opciones,
        });

        setLoading(false);
      });
    })();
  }, [configuraciones_ref[id_a]]);

  return (
    <VistaContext.Provider
      value={useMemo(() => {
        return {
          state,
          datos: state.datos,
          vistaBotones: state.vistaBotones,
          cabeceras: state.cabeceras,
          opciones: state.opciones,
          id: id,
          filtros,
          setFiltro,
          VistaDispatch: dispatch,
          Dispatch: dispatch,
          dispatch,
          qsBody,
          indiceConf,
        };
      }, [state, id, filtros, setFiltro, qsBody])}
    >
      {loading || loadingPantalla ? (
        <div
          style={{
            position: "absolute",
            height: "100vh",
            width: "100vw",
            backgroundColor: "rgba(200, 200, 200, 0.3)",
            zIndex: 30000,
          }}
        ></div>
      ) : (
        <></>
      )}
      <>{children}</>
    </VistaContext.Provider>
  );
};

const Vista = ({ configuracion, id, nollamar, qsBody, indiceConf }) => {
  return (
    <VistaProvider
      configuracion={configuracion}
      id={id}
      nollamar={nollamar}
      qsBody={qsBody}
    >
      <Tarjeta indiceConf={indiceConf} />
    </VistaProvider>
  );
};

export default Vista;

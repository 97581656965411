import React from "react";
import { FormControlLabel } from "@mui/material";

import { FormControl, Radio, RadioGroup } from "@mui/material";
import Label from "./LabelF";

const RadioF = (props) => {
  const {
    filtrosAAplicar,
    setFiltrosAAplicar,
    radio_opciones,
    id_a,
    grid_span,
  } = props;

  const handleRadio = (e) => {
    setFiltrosAAplicar((prevState) => {
      if (!e.target.value.trim()) {
        const newState = { ...prevState };
        newState[id_a] = null;

        return newState;
      }

      return {
        ...prevState,
        [id_a]: e.target.value,
      };
    });
  };

  const styles = {
    gridColumn: grid_span,
    borderBottom: props.error[id_a] ? "red solid 1px" : "unset",
  };

  return (
    <FormControl style={styles} error={props.error[id_a]}>
      <Label
        label={props.label}
        opcionales_null={props.opcionales_null}
        permite_null={props.permite_null}
        style={{ marginBottom: "0px" }}
      />
      <div className="filtro_grid_radio_button">
        <RadioGroup
          value={filtrosAAplicar[id_a] ?? ""}
          onChange={(e) => handleRadio(e)}
          row
        >
          {radio_opciones.map((item) => {
            return (
              <FormControlLabel
                key={item.label + "_" + item.value}
                value={item.value}
                control={<Radio size="small" />}
                label={item.label}
                className="radio_button"
              />
            );
          })}

          {props.select_todas !== "n" ? (
            <FormControlLabel
              key="Todas"
              value={null}
              control={<Radio size="small" />}
              label={"Todas"}
              checked={filtrosAAplicar[id_a] === null || !filtrosAAplicar[id_a]}
              className="radio_button"
            />
          ) : (
            <></>
          )}
        </RadioGroup>
      </div>
    </FormControl>
  );
};

export default RadioF;

import React from "react";

const Select = (props) => {
  const { nombre, opciones, onChange, value, def, id, disabled } = props;

  return (
    <select
      name={nombre}
      value={value ?? def}
      onChange={onChange}
      defaultValue={def}
      className={"tarjeta_grid_item_select select_listado " + props.className}
      disabled={disabled}
    >
      {opciones.map((op) => {
        return (
          <option
            key={op.value?.toString()}
            value={op.value}
            disabled={op.disabled}
            className="select_listado_opciones"
          >
            {op.label}
          </option>
        );
      })}
    </select>
  );
};

export default Select;

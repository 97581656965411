import React, { useCallback, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import Label from "./LabelF";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@material-ui/core";
import ar from "date-fns/locale/es";
import { TextField } from "@mui/material";
import "../filtros.scss";
import { parseISOString } from "../../../context/FuncionesContext";
registerLocale("ar", ar);

const useStyle = makeStyles({
  dateComponent: {
    "& .MuiInputBase-input": {
      // height: "1.55em",
      // padding: "16.5px 14px",
    },
  },
  inputDate: {
    "& .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root": {
      //  height: "55.5px",
    },
  },
});

const Fecha = (props) => {
  const {
    id_a,
    setFiltrosAAplicar,
    filtrosAAplicar,
    operador,
    componente,
    grid_span,
    error,
    setError,
  } = props;

  const parseFecha = useCallback((fecha) => {
    if (!fecha) return [null];

    try {
      if (Array.isArray(JSON.parse(fecha))) {
        let fechas = JSON.parse(fecha);

        if (fechas[0]) fechas[0] = parseISOString(fechas[0]);
        if (fechas[1]) fechas[1] = parseISOString(fechas[1]);
        return fechas;
      }
      return [parseISOString(fecha)];
    } catch (e) {
      return [parseISOString(fecha)];
    }
  }, []);

  const [fechas, setFechas] = useState([
    parseFecha(filtrosAAplicar[id_a])[0],
    parseFecha(filtrosAAplicar[id_a])[1],
  ]);

  const startDate = parseFecha(filtrosAAplicar[id_a])[0];

  const endDate = parseFecha(filtrosAAplicar[id_a])[1];

  const styles = {
    gridColumn: grid_span,
    // height: "82.3px",
  };

  const classes = useStyle();

  const fecha_formato = (() => {
    if (props.fecha_formato && props.fecha_formato !== "")
      return props.fecha_formato;
    if (operador !== "fecha_hora") return "dd/MM/yyyy";
    return "dd/MM/yyyy, hh:mm";
  })();

  return (
    <div className="filtroFecha" style={styles}>
      <div>
        <Label
          label={props.label}
          opcionales_null={props.opcionales_null}
          permite_null={props.permite_null}
        />
        <div className="filtro_grid_fecha">
          <DatePicker
            className="fecha_input"
            showTimeSelect={
              operador === "fecha_hora" || fecha_formato.includes("hh")
            }
            showYearDropdown
            timeFormat="p"
            timeIntervals={15}
            dateFormat={fecha_formato}
            locale="ar"
            placeholderText={" Desde"}
            onChange={(date) => {
              //setStartDate(date);
              const nuevasFechas = [...fechas];
              nuevasFechas[0] = date?.toISOString();

              setFechas((s) => {
                return nuevasFechas;
              });
              setError((e) => {
                return { ...e, [id_a]: false };
              });
              setFiltrosAAplicar((prevState) => {
                if (!date?.toISOString().trim()) {
                  return { ...prevState, [id_a]: "" };
                }
                if (componente === "fecha_simple") {
                  return {
                    ...prevState,
                    [id_a]: date?.toISOString(),
                  };
                }

                // let fechas = [];
                // if (prevState[id_a]) {
                //   fechas = JSON.parse(prevState[id_a]);
                // }
                // fechas[0] = date?.toISOString();

                if (
                  nuevasFechas.length !== 2 ||
                  !nuevasFechas.every((el) => el !== undefined && el !== null)
                )
                  return prevState;

                return {
                  ...prevState,
                  [id_a]: JSON.stringify(nuevasFechas),
                };
              });
            }}
            selectsStart
            selected={parseISOString(fechas[0])}
            startDate={parseISOString(fechas[0])}
            endDate={parseISOString(fechas[1])}
            // minDate={fechas[0]}
            customInput={
              <TextField
                className={
                  (error[id_a]
                    ? `filtro_fecha_input_error`
                    : classes.dateComponent,
                  classes.inputDate)
                }
                //style={{ marginTop: props.label ? "6.1px" : "0px" }}
                label="Desde"
                variant="standard"
              />
            }
            isClearable
            label="Desde"
            withPortal
          />
          {componente === "fecha_simple" ? null : (
            <DatePicker
              className="fecha_input"
              showTimeSelect={operador === "fecha_hora"}
              showYearDropdown
              timeFormat="p"
              timeIntervals={15}
              dateFormat={fecha_formato}
              locale="ar"
              placeholderText={" Hasta"}
              onChange={(date) => {
                //setEndDate(date);
                const nuevasFechas = [...fechas];
                nuevasFechas[1] = date?.toISOString();

                setFechas((s) => {
                  return nuevasFechas;
                });
                setFiltrosAAplicar((prevState) => {
                  // let fechas = [];

                  // if (prevState[id_a]) {
                  //   fechas = JSON.parse(prevState[id_a]);
                  // }

                  // fechas[1] = date?.toISOString();
                  if (
                    nuevasFechas.length !== 2 ||
                    !nuevasFechas.every((el) => el !== undefined && el !== null)
                  )
                    return prevState;

                  return {
                    ...prevState,
                    [id_a]: JSON.stringify(nuevasFechas),
                  };
                });
              }}
              selectsEnd
              selected={parseISOString(fechas[1])}
              startDate={parseISOString(fechas[0])}
              endDate={parseISOString(fechas[1])}
              minDate={parseISOString(fechas[0]) ?? null}
              customInput={
                <TextField
                  label="Hasta"
                  className={
                    (error[id_a]
                      ? `filtro_fecha_input_error`
                      : classes.dateComponent,
                    classes.inputDate)
                  }
                  //style={{ marginTop: "6.1px" }}
                  variant="standard"
                />
              }
              isClearable
              withPortal
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Fecha;

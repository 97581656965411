import React, { useContext } from "react";
import FuncionesContext from "../../context/FuncionesContext";
import { Link } from "react-router-dom";
import { parseQueryString } from "../../context/FuncionesContext";

export function OMensaje(props) {
  const { modalProps, configuracion, qsBody, params = {} } = props;
  const { opciones } = configuracion;
  let { mensaje, enlace_id_a, className, id_a } = opciones;

  const func = useContext(FuncionesContext);

  const search = "?" + enlace_id_a?.split("?")[1];
  const enlace = enlace_id_a?.split("?")[0];

  function convertirBuffer(data) {
    try {
      if (Buffer.isBuffer(data.data) || data.type === "Buffer") {
        const b = Buffer.from(data.data);
        return b.toString("utf-8");
      }
      return data;
    } catch (err) {
      console.log(err);
    }
  }

  mensaje = convertirBuffer(mensaje);

  if (enlace_id_a && enlace_id_a !== "") {
    if (opciones.target === "modal") {
      return (
        <div
          onClick={() =>
            func.enviarAModal(
              enlace,
              params,
              { min_width_modal: opciones.min_width_modal },
              Object.assign(qsBody, parseQueryString(search))
            )
          }
          className={className}
          id={id_a}
          style={{ cursor: "pointer" }}
        >
          {mensaje}
        </div>
      );
    }
    return (
      <div className={className}>
        <Link
          to={{ pathname: `/pantalla/${enlace}`, search }}
          target={opciones.target}
        >
          {mensaje}
        </Link>
      </div>
    );
  }
  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: mensaje }}
    ></div>
  );
}

import React, { useContext } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from "@fortawesome/free-solid-svg-icons";

import FuncionesContext from "../../../context/FuncionesContext";

function EnlaceAModal({
  cab,
  id_elemento,
  data,
  paramObj,
  nombre,
  qsBody,
  enlace_id_a,
  campokey,
}) {
  const { enviarAModal } = useContext(FuncionesContext);
  return (
    <div
      onClick={() =>
        enviarAModal(
          enlace_id_a,
          paramObj,
          { min_width_modal: cab.min_width_modal },
          qsBody
        )
      }
      className={cab.className}
      id={id_elemento}
      title={cab.tooltip_texto}
      style={{ display: "flex" }}
    >
      {nombre ? (
        <div className="vista_label" style={{ fontWeight: "bold" }}>
          {nombre}:{" "}
        </div>
      ) : (
        <></>
      )}
      <div>
        <ContenidoDeEnlace
          texto={
            data[campokey] ?? data[cab.boton_texto_alias] ?? cab.boton_texto
          }
          imagen={cab.imagen_url}
        />
      </div>
    </div>
  );
}

export default EnlaceAModal;

export function ContenidoDeEnlace({ texto, imagen }) {
  if (imagen && imagen !== "") {
    switch (imagen.toLowerCase()) {
      case "historial":
        return <FontAwesomeIcon icon={faBook} />;

      default:
        return (
          <img
            style={{ cursor: "pointer" }}
            height={"40px"}
            src={imagen}
            alt="imagen"
          />
        );
    }
  }

  if (texto && texto !== "") {
    return texto;
  }
  return <></>;
}

import React, { useEffect } from "react";
import SwitchCampos from "../../Vista/components/SwitchCampos";

function MapaInfo({ selectedElement, mapaInfo }) {
  
  useEffect(() => {
    //const mapInfoDiv = document.getElementById(mapaInfo.id_a);

    // console.log(mapaInfo, selectedElement);
    // console.log(mapInfoDiv, mapInfoDiv.parentElement)

    // const mapInfoDivP1 = mapInfoDiv.parentElement;
    // mapInfoDivP1.id = mapaInfo.id_a + "P1";
    // const mapInfoDivP2 = mapInfoDivP1.parentElement;
    // mapInfoDivP2.id = mapaInfo.id_a + "P2";
    // const mapInfoDivP3 = mapInfoDivP2.parentElement;
    // mapInfoDivP3.id = mapaInfo.id_a + "P3";

    // console.log(mapInfoDivP1, mapInfoDivP2, mapInfoDivP3);
  }, [mapaInfo.id_a]);

  return (
    <div
      id={mapaInfo.id_a + "_div"}
      style={{
        gridColumn: "span 12",
      }}
      className={`tarjeta_grid_item ${mapaInfo.className}`}
    >
      {mapaInfo.sc_hijos
        ?.filter((cab) => cab.componente !== "hidden")
        .sort((a, b) => a.orden - b.orden)
        .map((cab, i) => {
          return (
            <SwitchCampos
              key={mapaInfo.id_a}
              // indiceData={indiceData}
              // indiceConf={indiceConf}
              data={selectedElement}
              datos={selectedElement}
              cab={cab}
              id_elemento={cab.id_a + 1}
              // id_global={id}
              // qsBody={qsBody}
            />
          );
        })}
    </div>
  );
}

export default MapaInfo;

import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import ReactDOMServer from "react-dom/server";

const InfoWindow = ({
  google,
  map,
  mapCenter,
  marker,
  position,
  visible,
  children,
  onClose,
  onOpen,
}) => {
  const infoWindowRef = useRef(null);

  useEffect(() => {
    if (!google || !google.maps) return;

    // Create the InfoWindow instance
    infoWindowRef.current = new google.maps.InfoWindow({
      content: "",
    });

    const infoWindow = infoWindowRef.current;

    const handleOpen = () => {
      if (onOpen) onOpen();
    };

    const handleClose = () => {
      if (onClose) onClose();
    };

    // Set event listeners
    google.maps.event.addListener(infoWindow, "closeclick", handleClose);
    google.maps.event.addListener(infoWindow, "domready", handleOpen);

    // Cleanup on unmount
    return () => {
      google.maps.event.clearInstanceListeners(infoWindow);
    };
  }, [google, onClose, onOpen]);

  useEffect(() => {
    if (!infoWindowRef.current || !map) return;

    const infoWindow = infoWindowRef.current;

    if (visible) {
      infoWindow.open(map, marker);
    } else {
      infoWindow.close();
    }

    // Update position
    if (position) {
      const pos =
        position instanceof google.maps.LatLng
          ? position
          : new google.maps.LatLng(position.lat, position.lng);
      infoWindow.setPosition(pos);
    }

    // Update content
    if (children) {
      const content = ReactDOMServer.renderToString(children);
      infoWindow.setContent(content);
    }
  }, [google, map, marker, position, visible, children]);

  return null;
};

InfoWindow.propTypes = {
  children: PropTypes.element.isRequired,
  map: PropTypes.object,
  marker: PropTypes.object,
  position: PropTypes.object,
  visible: PropTypes.bool,

  // callbacks
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
};

InfoWindow.defaultProps = {
  visible: false,
};

export default InfoWindow;

export default function ABMReducer(state, action) {
  switch (action.type) {
    case "SET_FORMULARIO_VALOR": {
      const { id_a, valor } = action.payload;
      const formularioValor = state.formularioValor;
      if (valor === undefined || valor === 'undefined') return { ...state };

      formularioValor[id_a] = valor;
      return { ...state, formularioValor };
    }

    case "SET_FORMULARIO_INICIAL": {
      const { id_a, valor } = action.payload;
      const formularioInicial = { ...state.formularioInicial };

      formularioInicial[id_a] = valor;
      return { ...state, formularioInicial };
    }

    case "SET_FORMULARIO_OBLIGATORIO": {
      const { id_a } = action.payload;
      let obligatorio = state.obligatorio ?? [];

      obligatorio.push(id_a);
      obligatorio = new Set(obligatorio);
      obligatorio = Array.from(obligatorio);
      return { ...state, obligatorio };
    }

    case "SET_ERROR": {
      const { id_a, valor } = action.payload;
      const error = { ...state.error };

      error[id_a] = valor;
      return { ...state, error };
    }

    case "LIMPIAR_FORMULARIO": {
      return { ...state, formularioValor: { ...state.formularioInicial } };
    }

    case "SET_DATOS":
      return {
        ...state,
        datos: action.payload,
        formularioValor: {},
      };
    case "SET_CABECERAS":
      return {
        ...state,
        cabeceras: action.payload,
      };
    case "SET_FILTROS": {
      return {
        ...state,
        filtros: action.payload,
      };
    }
    case "SET_LOADING": {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case "SET_OPCIONES": {
      return {
        ...state,
        opciones: action.payload,
      };
    }

    case "ADD_CONFIGURACIONES_REF":
      const confs_cabs = { ...state.configuraciones_cab };
      action.payload.forEach((cab) => {
        confs_cabs[cab.id_a] = cab;
      });
      return {
        ...state,
        configuraciones_cab: confs_cabs,
      };

    case "SET_ID_GLOBAL": {
      return {
        ...state,
        id_global: action.payload,
      };
    }
    case "SET_DATO_ESPECIFICO":
      const { value, indiceData, key } = action.payload;

      const nuevosDatos = state.datos;

      nuevosDatos[indiceData][key] = value;
      return {
        ...state,
        datos: nuevosDatos,
      };
    default:
      return state;
  }
}

export const initialState = {
  opcionesABM: {},
  datos: [],
  cabeceras: [],
  filtros: [],
  obligatorio: [],
  error: [],
  formularioValor: {},
  formularioInicial: {},
  configuraciones_cab: {},
  loading: true,
};

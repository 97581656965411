import React, {
  useReducer,
  createContext,
  useMemo,
  useEffect,
  useCallback,
} from "react";
import { useRefStore, useRefDataStore } from "../../..";
import { killConfiguracion, deleteData } from "./PantallaReducer";

export const initialState = {
  modales: [], // ejemplo : [{ id_a: "UN_MODAL", open: false, data: {}, zIndex }],
  zIndex: 1030,
};

export const ModalesContext = createContext();
export default ModalesContext;

export const ModalProvider = (props) => {
  const [state, dispatch] = useReducer(ModalReducer, initialState);

  const [configuraciones, setRefState] = useRefStore((s) => s.configuraciones);
  // const [refDataState, setRefDataState, removeFromDataState] =
  //   useRefDataStore();

  const abrirModal = (id_a, data) => {
    dispatch({ type: "OPEN_MODAL", payload: { id_a, data } });
  };

  const cerrarModal = useCallback(
    (id_a) => {
      //  const newDataState = deleteData(id_a, refState, refDataState);
      //  removeFromDataState(newDataState);
      setRefState({
        configuraciones: killConfiguracion(id_a, { configuraciones }),
      });
      dispatch({ type: "CLOSE_MODAL", payload: { id_a } });
    },
    [configuraciones, setRefState]
  );

  const addModal = useCallback(
    ({ id_a, data, parametro_id, propsModal, qsBody }) => {
      dispatch({
        type: "ADD_MODAL",
        payload: {
          id_a,
          open: true,
          data,
          parametro_id,
          zIndex: state.modales.length * 10 + state.zIndex,
          cerrar: () => cerrarModal(id_a, data),
          propsModal,
          props: propsModal,
          qsBody,
        },
      });
    },
    [cerrarModal, state.modales.length, state.zIndex]
  );

  const getModal = useCallback(
    (id_a) => {
      const modal = state.modales.find((m) => m.id_a === id_a);

      if (!modal) return {};
      return modal;
    },
    [state.modales]
  );

  const cerrar = useCallback(
    (id_a) => {
      const m = getModal(id_a);
      m.cerrar();
    },
    [getModal]
  );

  // useEffect(() => {
  //   setRefState(props.refState.configuraciones);
  // }, [props.refState, setRefState]);

  return (
    <ModalesContext.Provider
      value={useMemo(() => {
        return {
          state,
          modales: state.modales,
          zIndex: state.zIndex,
          abrirModal,
          cerrarModal: cerrar,
          addModal,
          getModal,
          dispatch,
        };
      }, [addModal, cerrar, getModal, state])}
    >
      {props.children}
    </ModalesContext.Provider>
  );
};

export const ModalReducer = (state, action) => {
  const ns = state.modales;
  let modal = {}; //ns.find((m) => m.id_a === action.payload.id_a);
  let mindex = ns.findIndex((m) => m.id_a === action.payload.id_a);

  switch (action.type) {
    case "ADD_MODAL":
      const nm = state.modales;
      nm.push(action.payload);
      return {
        ...state,
        modales: nm,
      };

    case "OPEN_MODAL":
      modal.open = true;
      modal.data = action.payload.data;
      ns[mindex] = modal;
      return {
        ...state,
        modales: ns,
        zIndex: state.zIndex + state.modales.length,
      };

    case "CLOSE_MODAL":
      ns.splice(mindex, 1);
      return {
        ...state,
        modales: ns,
      };

    default:
      return state;
  }
};

export default function PantallaReducer(state, action) {
  switch (action.type) {
    case "SET_CONFIGURACIONES":
      return {
        ...state,
        configuraciones: action.payload,
      };

    case "ADD_CONFIGURACION":
      // aniade conf para funcion de renderizado
      try {
        let nc = state.configuraciones;
        nc.push(action.payload);

        // aniade conf para funcion refrescar
        let nrc = { ...state.configuraciones_ref };
        let nri = { ...state.configuraciones_ids };
        let nrt = { ...state.configuraciones_tipo };
        let nrp = { ...state.configuraciones_padre };
        (() => {
          const data = action.payload;

          nrc[data.opciones.id_a] = Number(1);
          nri[data.opciones.id_a] = data.opciones.id;
          nrt[data.opciones.id_a] = data.opciones.tipo.id ?? "-1";
          nrp[data.opciones.id_a] = data.opciones.padre;

          const goConf = (cab) => {
            if (!cab.sc_hijos) return;
            return cab.sc_hijos?.forEach((sc) => {
              nrc[sc.id_a] = Number(1);
              nri[sc.id_a] = sc.id;
              nrt[sc.id_a] = sc.tipo.id ?? "-1";
              nrp[sc.id_a] = sc.padre;
              goConf(sc.sc_hijos);
            });
          };

          data.configuraciones.forEach((c) => {
            nrc[c.opciones.id_a] = Number(1);
            nri[c.opciones.id_a] = c.opciones.id;
            nrt[c.opciones.id_a] = c.opciones.tipo.id ?? "-1";
            nrp[c.opciones.id_a] = c.opciones.padre;

            // eslint-disable-next-line no-unused-expressions
            c.configuraciones?.forEach((sc) => {
              nrc[sc.opciones.id_a] = Number(1);
              nri[sc.opciones.id_a] = sc.opciones.id;
              nrt[sc.opciones.id_a] = sc.opciones.tipo.id ?? "-1";
              nrp[sc.opciones.id_a] = sc.opciones.padre;
              // eslint-disable-next-line no-unused-expressions
              sc.cabeceras?.forEach((scc) => {
                nrc[scc.id_a] = Number(1);
                nri[scc.id_a] = scc.id;
                nrt[scc.id_a] = scc.tipo.id ?? "-1";
                nrp[scc.id_a] = scc.padre;
                goConf(scc);
              });
              // eslint-disable-next-line no-unused-expressions
              sc.filtros?.forEach((scc) => {
                nrc[scc.id_a] = Number(1);
                nri[scc.id_a] = scc.id;
                nrt[scc.id_a] = scc.tipo.id ?? "-1";
                nrp[scc.id_a] = scc.padre;
                goConf(scc);
              });
            });
            return;
          });
        })();

        return {
          ...state,
          configuraciones: nc,
          configuraciones_ref: nrc,
          configuraciones_ids: nri,
          configuraciones_tipo: nrt,
          configuraciones_padre: nrp,
        };
      } catch (err) {
        console.log(err);
        return { ...state };
      }

    case "KILL_CONFIGURACION":
      let ncc = state.configuraciones;
      let cindx = ncc.findIndex((c) => c.opciones.id_a === action.payload);
      ncc.splice(cindx, 1);

      return {
        ...state,
        configuraciones: ncc,
      };

    case "SET_OPCIONES_DE_PANTALLA":
      return {
        ...state,
        opciones_de_pantalla: action.payload,
      };

    case "SET_PANTALLA":
      return {
        ...state,
        pantalla: action.payload,
      };

    case "SET_PANTALLA_ID":
      return {
        ...state,
        pantalla_id: action.payload,
      };
    case "SET_CONFIGURACIONES_REF":
      let configuraciones_ida = {};
      let configuraciones_ids = {};
      let configuraciones_tipo = {};
      let configuraciones_padre = {};
      let configuraciones_cab = {};

      let data = action.payload;

      configuraciones_ida[data.opciones.id_a] = Number(1);
      configuraciones_ids[data.opciones.id_a] = data.opciones.id;
      configuraciones_tipo[data.opciones.id_a] = data.opciones.tipo.id;
      configuraciones_padre[data.opciones.id_a] = data.opciones.padre;

      let goConf = (cab) => {
        if (!cab.sc_hijos) return;
        return cab.sc_hijos?.forEach((sc) => {
          configuraciones_ida[sc.id_a] = Number(1);
          configuraciones_ids[sc.id_a] = sc.id;
          configuraciones_tipo[sc.id_a] = sc.tipo.id;
          configuraciones_padre[sc.id_a] = sc.padre;
          configuraciones_cab[sc.id_a] = sc;
          goConf(sc.sc_hijos);
        });
      };

      data.configuraciones.forEach((c) => {
        configuraciones_ida[c.opciones.id_a] = Number(1);
        configuraciones_ids[c.opciones.id_a] = c.opciones.id;
        configuraciones_tipo[c.opciones.id_a] = c.opciones.tipo.id;
        configuraciones_padre[c.opciones.id_a] = c.opciones.padre;
        // eslint-disable-next-line no-unused-expressions
        c.configuraciones?.forEach((sc) => {
          configuraciones_ida[sc.opciones.id_a] = Number(1);
          configuraciones_ids[sc.opciones.id_a] = sc.opciones.id;
          configuraciones_tipo[sc.opciones.id_a] = sc.opciones.tipo.id;
          configuraciones_padre[sc.opciones.id_a] = sc.opciones.padre;
          // eslint-disable-next-line no-unused-expressions
          sc.cabeceras?.forEach((scc) => {
            configuraciones_ida[scc.id_a] = Number(1);
            configuraciones_ids[scc.id_a] = scc.id;
            configuraciones_tipo[scc.id_a] = scc.tipo.id;
            configuraciones_padre[scc.id_a] = scc.padre;
            configuraciones_cab[scc.id_a] = scc;
            goConf(scc);
          });
          if (sc.filtros) {
            // eslint-disable-next-line no-unused-expressions
            sc.filtros?.forEach((ssc) => {
              configuraciones_ida[ssc.id_a] = Number(1);
              configuraciones_ids[ssc.id_a] = ssc.id;
              configuraciones_tipo[ssc.id_a] = ssc.tipo.id;
              configuraciones_padre[ssc.id_a] = ssc.padre;
              configuraciones_cab[ssc.id_a] = ssc;
              goConf(ssc);
            });
          }
        });
        if (c.filtros) {
          // eslint-disable-next-line no-unused-expressions
          c.filtros?.forEach((sc) => {
            configuraciones_ida[sc.id_a] = Number(1);
            configuraciones_ids[sc.id_a] = sc.id;
            configuraciones_tipo[sc.id_a] = sc.tipo.id;
            configuraciones_padre[sc.id_a] = sc.padre;
            configuraciones_cab[sc.id_a] = sc;
            goConf(sc);
          });
        }

        return c.cabeceras?.forEach((sc) => {
          configuraciones_ida[sc.id_a] = Number(1);
          configuraciones_ids[sc.id_a] = sc.id;
          configuraciones_tipo[sc.id_a] = sc.tipo.id;
          configuraciones_padre[sc.id_a] = sc.padre;
          configuraciones_cab[sc.id_a] = sc;
          goConf(sc);
        });
      });
      return {
        ...state,
        configuraciones_ref: configuraciones_ida,
        configuraciones_ids: configuraciones_ids,
        configuraciones_tipo: configuraciones_tipo,
        configuraciones_padre: configuraciones_padre,
        configuraciones_cab: configuraciones_cab,
      };

    case "REFRESCAR":
      //if (!state) return state;
      if (!state.configuraciones_ref[action.payload.id]) {
        console.log(
          "La configuracion que intenta actualizar posiblemente no existe"
        );
        return state;
      }
      if (
        [1, 2, 6, 7, 9].includes(state.configuraciones_tipo[action.payload.id])
      ) {
        return {
          ...state,
          configuraciones_ref: {
            ...state.configuraciones_ref,
            [action.payload.id]:
              state.configuraciones_ref[action.payload.id] + 1,
          },
        };
      }
      action.payload.ref({
        cab: {
          refrescarConfiguracion:
            state.configuraciones_padre[action.payload.id],
        },
      });
      return state;

    case "REFRESCAR_FILTRO":
      //if (!state) return state;
      if (!state.configuraciones_ref[action.payload.id]) {
        console.log(
          "La configuracion que intenta actualizar posiblemente no existe"
        );
        return state;
      }
      if ([3, 4].includes(state.configuraciones_tipo[action.payload.id])) {
        return {
          ...state,
          configuraciones_ref: {
            ...state.configuraciones_ref,
            [action.payload.id]:
              state.configuraciones_ref[action.payload.id] + 1,
          },
        };
      }

      return state;

    case "SET_FILTROS_APLICADOS": // de momento no se usa. Se usa SET_FILTRO_ACTIVO en context de listado
      if (!action.payload) return state;
      const filtrosAAplicar = action.payload.filtrosAAplicar;
      const id_a = action.payload.id_a;

      const nuevosFiltros = state.filtrosAplicados;

      nuevosFiltros[id_a] = filtrosAAplicar;

      return {
        ...state,
        filtrosAplicados: nuevosFiltros,
      };

    case "SET_DATOS_CONF":
      const newState = { ...state };

      newState.configuraciones[action.payload.indiceConf] =
        action.payload.configuracion;

      return newState;

    case "ADD_SQL":
      let newSql = [...state.sql];
      newSql = newSql.concat(action.payload).filter((s) => s);
      return {
        ...state,
        sql: newSql,
      };

    default:
      return state;
  }
}

export const initialState = {
  configuraciones: [],
  opciones_de_pantalla: {},
  configuraciones_ref: {},
  configuraciones_ida: {},
  configuraciones_ids: {},
  configuraciones_tipo: {},
  configuraciones_padre: {},
  configuraciones_cab: {},
  configuracionesDatos: {},
  filtrosAplicados: {},
  sql: [],
};

export const configuracionesRef = (payload) => {
  let configuraciones_ida = {};
  let configuraciones_ids = {};
  let configuraciones_tipo = {};
  let configuraciones_padre = {};
  let configuraciones_cab = {};
  let configuracionesDatos = {};

  let data = payload;

  configuraciones_ida[data.opciones.id_a] = Number(1);
  configuraciones_ids[data.opciones.id_a] = data.opciones.id;
  configuraciones_tipo[data.opciones.id_a] = data.opciones.tipo.id;
  configuraciones_padre[data.opciones.id_a] = data.opciones.padre;

  let goConf = (cab) => {
    if (!cab?.sc_hijos) return;
    return cab.sc_hijos?.forEach((sc) => {
      configuraciones_ida[sc.id_a] = Number(1);
      configuraciones_ids[sc.id_a] = sc.id;
      configuraciones_tipo[sc.id_a] = sc.tipo.id;
      configuraciones_padre[sc.id_a] = sc.padre;
      configuraciones_cab[sc.id_a] = sc;
      goConf(sc.sc_hijos);
    });
  };

  data.configuraciones.forEach((c) => {
    if (c.datos) {
      c.datos.forEach((d) => {
        const keys = Object.keys(d);
        keys.forEach((k) => {
          configuracionesDatos[d._key + k] = d[k];
        });
      });
      //  delete c.datos
    }

    configuraciones_ida[c.opciones.id_a] = Number(1);
    configuraciones_ids[c.opciones.id_a] = c.opciones.id;
    configuraciones_tipo[c.opciones.id_a] = c.opciones.tipo.id;
    configuraciones_padre[c.opciones.id_a] = c.opciones.padre;
    // eslint-disable-next-line no-unused-expressions
    c.configuraciones?.forEach((sc) => {
      if (sc.datos) {
        sc.datos.forEach((d) => {
          configuracionesDatos[d._key] = d;
        });
        //  delete sc.datos
      }
      configuraciones_ida[sc.opciones.id_a] = Number(1);
      configuraciones_ids[sc.opciones.id_a] = sc.opciones.id;
      configuraciones_tipo[sc.opciones.id_a] = sc.opciones.tipo.id;
      configuraciones_padre[sc.opciones.id_a] = sc.opciones.padre;
      // eslint-disable-next-line no-unused-expressions
      sc.cabeceras?.forEach((scc) => {
        configuraciones_ida[scc.id_a] = Number(1);
        configuraciones_ids[scc.id_a] = scc.id;
        configuraciones_tipo[scc.id_a] = scc.tipo.id;
        configuraciones_padre[scc.id_a] = scc.padre;
        configuraciones_cab[scc.id_a] = scc;
        goConf(scc);
      });
      if (sc.filtros) {
        // eslint-disable-next-line no-unused-expressions
        sc.filtros?.forEach((ssc) => {
          configuraciones_ida[ssc.id_a] = Number(1);
          configuraciones_ids[ssc.id_a] = ssc.id;
          configuraciones_tipo[ssc.id_a] = ssc.tipo.id;
          configuraciones_padre[ssc.id_a] = ssc.padre;
          configuraciones_cab[ssc.id_a] = ssc;
          goConf(ssc);
        });
      }
    });
    if (c.filtros) {
      // eslint-disable-next-line no-unused-expressions
      c.filtros?.forEach((sc) => {
        configuraciones_ida[sc.id_a] = Number(1);
        configuraciones_ids[sc.id_a] = sc.id;
        configuraciones_tipo[sc.id_a] = sc.tipo.id;
        configuraciones_padre[sc.id_a] = sc.padre;
        configuraciones_cab[sc.id_a] = sc;
        goConf(sc);
      });
    }

    return c.cabeceras?.forEach((sc) => {
      configuraciones_ida[sc.id_a] = Number(1);
      configuraciones_ids[sc.id_a] = sc.id;
      configuraciones_tipo[sc.id_a] = sc.tipo.id;
      configuraciones_padre[sc.id_a] = sc.padre;
      configuraciones_cab[sc.id_a] = sc;
      goConf(sc);
    });
  });
  return {
    configuraciones_ref: configuraciones_ida,
    configuraciones_ids: configuraciones_ids,
    configuraciones_tipo: configuraciones_tipo,
    configuraciones_padre: configuraciones_padre,
    configuraciones_cab: configuraciones_cab,
    configuracionesDatos,
  };
};

export const addData = (payload) => {
  const configuracion = payload;
  const configuracionesDatos = {};

  if (configuracion?.datos) {
    configuracion.datos.forEach((d) => {
      const keys = Object.keys(d);
      keys.forEach((k) => {
        configuracionesDatos[d._key + k] = d[k];
      });

      if (configuracion.configuraciones) {
        configuracion.configuraciones.forEach((c) => {
          if (c.datos) {
            c.datos.forEach((d) => {
              const keys = Object.keys(d);
              keys.forEach((k) => {
                configuracionesDatos[d._key + k] = d[k];
              });
            });
          }
        });
      }
    });

    return configuracionesDatos;
  }
  return {};
};

export const addConfiguracion = (payload, state) => {
  // aniade conf para funcion de renderizado
  try {
    let nc = state.configuraciones;
    nc.push(payload);

    // aniade conf para funcion refrescar
    let nrc = { ...state.configuraciones_ref };
    let nri = { ...state.configuraciones_ids };
    let nrt = { ...state.configuraciones_tipo };
    let nrp = { ...state.configuraciones_padre };
    let configuracionesDatos = {};
    (() => {
      const data = payload;

      nrc[data.opciones.id_a] = Number(1);
      nri[data.opciones.id_a] = data.opciones.id;
      nrt[data.opciones.id_a] = data.opciones.tipo.id ?? "-1";
      nrp[data.opciones.id_a] = data.opciones.padre;

      const goConf = (cab) => {
        if (!cab.sc_hijos) return;
        return cab.sc_hijos?.forEach((sc) => {
          nrc[sc.id_a] = Number(1);
          nri[sc.id_a] = sc.id;
          nrt[sc.id_a] = sc.tipo.id ?? "-1";
          nrp[sc.id_a] = sc.padre;
          goConf(sc.sc_hijos);
        });
      };

      data.configuraciones.forEach((c) => {
        if (c.datos) {
          c.datos.forEach((d) => {
            const keys = Object.keys(d);
            keys.forEach((k) => {
              configuracionesDatos[d._key + k] = d[k];
            });
          });
        }
        nrc[c.opciones.id_a] = Number(1);
        nri[c.opciones.id_a] = c.opciones.id;
        nrt[c.opciones.id_a] = c.opciones.tipo.id ?? "-1";
        nrp[c.opciones.id_a] = c.opciones.padre;

        // eslint-disable-next-line no-unused-expressions
        c.configuraciones?.forEach((sc) => {
          if (sc.datos) {
            sc.datos.forEach((d) => {
              const keys = Object.keys(d);
              keys.forEach((k) => {
                configuracionesDatos[d._key + k] = d[k];
              });
            });
          }
          nrc[sc.opciones.id_a] = Number(1);
          nri[sc.opciones.id_a] = sc.opciones.id;
          nrt[sc.opciones.id_a] = sc.opciones.tipo.id ?? "-1";
          nrp[sc.opciones.id_a] = sc.opciones.padre;
          // eslint-disable-next-line no-unused-expressions
          sc.cabeceras?.forEach((scc) => {
            nrc[scc.id_a] = Number(1);
            nri[scc.id_a] = scc.id;
            nrt[scc.id_a] = scc.tipo.id ?? "-1";
            nrp[scc.id_a] = scc.padre;
            goConf(scc);
          });
          // eslint-disable-next-line no-unused-expressions
          sc.filtros?.forEach((scc) => {
            nrc[scc.id_a] = Number(1);
            nri[scc.id_a] = scc.id;
            nrt[scc.id_a] = scc.tipo.id ?? "-1";
            nrp[scc.id_a] = scc.padre;
            goConf(scc);
          });
        });
        return;
      });
    })();

    return {
      configuraciones: nc,
      configuraciones_ref: nrc,
      configuraciones_ids: nri,
      configuraciones_tipo: nrt,
      configuraciones_padre: nrp,
      configuracionesDatos,
    };
  } catch (err) {
    console.log(err);
    return { ...state };
  }
};

export const addsql = (payload, sql) => {
  if (!payload) return sql;
  let newSql = [...sql];
  newSql = newSql.concat(payload).filter((s) => s);
  return newSql;
};

export const killConfiguracion = (payload, state) => {
  let ncc = state.configuraciones;
  let cindx = ncc.findIndex((c) => c.opciones.id_a === payload);
  ncc.splice(cindx, 1);

  return ncc;
};

export const deleteData = (payload, state, dataState) => {
  let ncc = state.configuraciones;
  let cindx = ncc.findIndex((c) => c.opciones.id_a === payload);

  const deletedState = { ...dataState };
  if (ncc[cindx].datos) {
    ncc[cindx].datos.forEach((d) => {
      const keys = Object.keys(d);
      keys.forEach((k) => {
        delete deletedState[d._key + k];
      });
    });
  }
  if (ncc[cindx].configuraciones) {
    ncc[cindx].configuraciones.forEach((sc) => {
      sc.datos.forEach((d) => {
        const keys = Object.keys(d);
        keys.forEach((k) => {
          delete deletedState[d._key + k];
        });
      });
    });
  }
  return deletedState;
};

export const refrescar = (state, id_a, tipos = [1, 2, 6, 7, 9]) => {
  //if (!state) return state;
  if (!state.configuraciones_ref[id_a]) {
    console.log(
      "La configuracion que intenta actualizar posiblemente no existe: ",
      id_a
    );
    return state.configuraciones_ref;
  }
  if (tipos.includes(state.configuraciones_tipo[id_a])) {
    return {
      ...state.configuraciones_ref,
      [id_a]: state.configuraciones_ref[id_a] + 1,
    };
  }
  return refrescar(state, state.configuraciones_padre[id_a] ?? null);
};

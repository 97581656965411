import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Modal from "react-modal";

import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";
import { guardar } from "../../context/FuncionesContext";

export const LocalCarrusel = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const usarlc = searchParams.get("uselc") === "s";
  const id = searchParams.get("i");
  const [carrusel, setCarrusel] = useState(usarlc);
  const [datos, setDatos] = useState([]);
  const [cabeceras, setCabeceras] = useState([]);
  const [opciones, setOpciones] = useState({});

  useEffect(() => {
    setDatos(JSON.parse(localStorage.getItem(`datos_${id}`)));
    setOpciones(JSON.parse(localStorage.getItem(`opciones_${id}`)));
    setCabeceras(JSON.parse(localStorage.getItem(`cabeceras${id}`)));
    return () => {
      localStorage.removeItem(`datos_${id}`);
      localStorage.removeItem(`opciones_${id}`);
      localStorage.removeItem(`cabeceras${id}`);
    };
  }, [id]);

  return (
    <Carrusel
      data={datos}
      cabeceras={cabeceras}
      opcionesListado={opciones}
      usarlc={usarlc}
      carrusel={carrusel}
      setCarrusel={setCarrusel}
    />
  );
};

const Carrusel = (props) => {
  const {
    id_elemento,
    opcionesListado,
    data,
    cabeceras,
    campokey,
    setCarrusel,
    carrusel,
    usarlc,
  } = props;

  const [fileUrl, setFileUrl] = useState(
    process.env.PUBLIC_URL + data.enlaceURL
  );

  const openModal = (url) => {
    setFileUrl(url);
    setCarrusel(true);
  };

  const closeModal = () => {
    setCarrusel(false);
  };

  return (
    <div id={id_elemento}>
      <div
        style={{
          textAlign: "center",
        }}
        onClick={() => openModal(fileUrl)}
      >
        {data[campokey]}
      </div>
      <div>
        <Modal
          isOpen={carrusel}
          onRequestClose={closeModal}
          contentLabel="Visor de Imagen/jpg"
          shouldCloseOnEsc
          shouldCloseOnOverlayClick
          overlayClassName={"overlay_carrusel"}
          bodyOpenClassName={"open_modal_carrusel"}
          className="carrousel_modal"
        >
          <div className="cerrar-modal" style={{ width: "110px" }}>
            {usarlc ? (
              <></>
            ) : (
              <span
                style={{
                  background: "gray",
                  fontSize: "14px",
                  padding: "0.3rem 0.6rem",
                  cursor: "pointer",
                  borderRadius: "3px",
                }}
                onClick={() => closeModal()}
              >
                CERRAR X
              </span>
            )}
          </div>
          <CarouselWithZoom
            images={data.map((d) => {
              return (
                process.env.PUBLIC_URL +
                d[opcionesListado.usar_carrusel_imagen_key]
              );
            })}
            cabeceras={cabeceras
              .filter(
                (d) =>
                  d.id_a === opcionesListado.usar_carrusel_guardar_rotacion_conf
              )
              .pop()}
            data={data}
          />
        </Modal>
      </div>
    </div>
  );
};

export const CarouselWithZoom = ({ images, cabeceras, data }) => {
  const [panStart, setPanStart] = useState(null);
  const [panOffset, setPanOffset] = useState({ x: 0, y: 0 });
  const [rotacion, setRotacion] = useState(0);
  const [actualRotacion, setActualRotacion] = useState([]);

  const rotar = (actual) => {
    setRotacion((s) => s + 90);
    //  setActualRotacion((s) => s[actual] === rotacion + 90);

    if (cabeceras && data[actual][cabeceras.update_id_alias]) {
      guardar({
        valor: rotacion + 90,
        update_id: data[actual][cabeceras.update_id_alias],
        id_a: cabeceras.id_a,
      });
    }

    return rotacion + 90;
  };

  const handlePanStart = (e) => {
    setPanStart({ x: e.clientX, y: e.clientY });
  };
  const handlePanMove = (e) => {
    if (panStart) {
      const offsetX = e.clientX - panStart.x;
      const offsetY = e.clientY - panStart.y;
      setPanOffset((prevOffset) => ({
        x: prevOffset.x + offsetX,
        y: prevOffset.y + offsetY,
      }));
      setPanStart({ x: e.clientX, y: e.clientY });
    }
  };
  const handlePanEnd = () => {
    setPanStart(null);
  };
  const resetPan = () => {
    setPanOffset({ x: 0, y: 0 });
  };
  const resetRotacion = () => {
    setRotacion(0);
  };

  useEffect(() => {
    if (cabeceras) {
      const actr = data.map((d) => {
        return d[cabeceras.id_a];
      });
      setActualRotacion(actr);
    } else {
      const actr = images.map(() => 0);
      setActualRotacion(actr);
    }
  }, [cabeceras, data, images]);

  return (
    <SliderImg
      rotar={rotar}
      resetPan={resetPan}
      resetRotacion={resetRotacion}
      setRotacion={setRotacion}
      actualRotacion={actualRotacion}
      setActualRot={setActualRotacion}
    >
      {images.map((image, index) => {
        if (image.split(".").pop() === "pdf") {
          return (
            <iframe
              key={`Slide ${index}`}
              className="carrusel_slider_pdf"
              src={image}
              title={image}
            />
          );
        }

        return (
          <img
            className="carrusel_slider_img"
            title={`Slide ${index}`}
            key={`Slide ${index}`}
            src={image}
            alt={`Slide ${index}`}
            style={{
              transform: `translate(${panOffset.x}px, ${panOffset.y}px)  rotate(${rotacion}deg) `,
            }}
            onMouseDown={handlePanStart}
            onMouseMove={handlePanMove}
            onMouseUp={handlePanEnd}
            onMouseLeave={handlePanEnd}
          />
        );
      })}
    </SliderImg>
  );
};

export const SliderImg = ({
  children,
  resetPan,
  rotar,
  // resetRotacion,
  setRotacion,
  actualRotacion,
  setActualRot,
}) => {
  const total = children.length - 1;
  const [actual, setActual] = useState(0);
  const [zoom, setZoom] = useState(0);

  const resetZoom = () => {
    setZoom(0);
  };

  const siguiente = () => {
    resetPan();
    resetZoom();
    //  resetRotacion();
    if (actual === total) {
      setRotacion(actualRotacion[0]);
      return setActual(0);
    }
    setRotacion(actualRotacion[actual + 1]);
    return setActual((s) => s + 1);
  };
  const previa = () => {
    resetPan();
    resetZoom();
    //  resetRotacion();
    if (actual === 0) {
      setRotacion(actualRotacion[total]);
      return setActual(total);
    }
    setRotacion(actualRotacion[actual - 1]);
    return setActual((s) => s - 1);
  };
  const zoomear = (e) => {
    const { value } = e.target;
    setZoom(value);
  };

  useEffect(() => {
    setRotacion(actualRotacion[actual]);
  }, [actual, actualRotacion, setRotacion]);

  return (
    <div style={{ textAlign: "center", backgroundColor: "#191D1E" }}>
      <div style={{ height: "100vh", width: "100%" }}>
        <div
          className="carrusel_imagen"
          style={{
            transform: `scale(${1 + zoom / 100}) `,
          }}
        >
          {children[actual]}
        </div>
        <div className="carrusel_control">
          <div
            className="carrusel_control_btn carrusel_control_siguiente"
            onClick={() => previa()}
          >
            {"<"}
          </div>
          <div
            className="carrusel_control_btn carrusel_control_anterior"
            onClick={() => siguiente()}
          >
            {">"}
          </div>
          <div
            className="carrusel_control_btn"
            onClick={() => {
              const rot = rotar(actual);
              setActualRot((s) => {
                s[actual] = rot;
                return s;
              });
            }}
          >
            Rotar
          </div>

          <div className="carrusel_control_zoom">
            <Box sx={{ width: 200 }}>
              <Slider
                size="small"
                defaultValue={0}
                value={zoom}
                aria-label="Small"
                valueLabelDisplay="auto"
                onChange={(v) => zoomear(v)}
              />
            </Box>
            <div className="carrusel_control_zoom_cantidad">{`${
              100 + zoom
            }%`}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carrusel;

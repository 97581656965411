import React, { useContext, StrictMode } from "react";
import SubPantalla from "../SubPantalla/SubPantalla";
import { ListadoProvider } from "../Listado/Listado";
import ConfigListado from "../Listado/components/ConfigListado";
import Vista from "../Vista/Vista";
import ABMProvider from "../ABM/ABMProvider";
import ABM from "../ABM/ABM";
import Modal from "./Modal";
import RedirectP from "./RedirectP";
import ModalesContext from "../context/ModalContext";
import Mensaje from "./Mensaje/Mensaje_";

//cabeceras data opciones
const SwitchMaestro = ({
  configuracion,
  id,
  _key,
  nollamar,
  indiceConf,
  params,
  props,
  qsBody,
}) => {
  const modalContext = useContext(ModalesContext);
  const { getModal } = modalContext;

  if (props) {
    configuracion.opciones.esModal = props.esModal;
  }

  const calcComponente = ({
    configuracion,
    id,
    _key,
    nollamar,
    indiceConf,
    params,
    props,
    qsBody,
  }) => {
    if (!configuracion.opciones.tipo?.id) {
      console.log(configuracion);
      return <div>Error: {configuracion.id_a}</div>;
    }
    switch (configuracion.opciones.tipo.id) {
      //Error
      case -1:
        return <>Error: {configuracion.id_a}</>;
      //Listado
      case 2:
        return (
          <ListadoProvider
            key={_key}
            configuracion={configuracion}
            id={id}
            nollamar={nollamar}
            indiceConf={indiceConf}
            params={params}
            modalProps={props}
            qsBody={qsBody}
          >
            <ConfigListado />
          </ListadoProvider>
        );
      //Vista
      case 6:
        return (
          <Vista
            // key={_key}
            configuracion={configuracion}
            id={id}
            nollamar={nollamar}
            indiceConf={indiceConf}
            params={params}
            modalProps={props}
            qsBody={qsBody}
          />
        );
      case 7:
        return (
          <SubPantalla
            key={_key}
            configuracion={configuracion}
            id={id}
            nollamar={nollamar}
            indiceConf={indiceConf}
            params={params}
            modalProps={props}
            qsBody={qsBody}
          />
        );

      case 1:
        return (
          <SubPantalla
            key={_key}
            configuracion={configuracion}
            id={id}
            nollamar={nollamar}
            indiceConf={indiceConf}
            params={params}
            modalProps={props}
            qsBody={qsBody}
          />
        );

      case 9:
      case 21:
        return (
          <ABMProvider
            key={_key}
            configuracion={configuracion}
            id={id}
            nollamar={nollamar}
            indiceConf={indiceConf}
            params={params}
            modalProps={props}
            qsBody={qsBody}
          >
            <ABM />
          </ABMProvider>
        );

      case 11:
        return <RedirectP configuracion={configuracion} modalProps={props} />;
      case 14:
        return (
          <Mensaje
            qsBody={qsBody}
            params={params}
            modalProps={props}
            configuracion={configuracion}
          />
        );
      default:
        return <></>;
    }
  };

  if (
    configuracion.opciones.modal ||
    getModal(configuracion.opciones.id_a).id_a
  ) {
    const { getModal, cerrarModal } = modalContext;
    const modal = getModal(configuracion.opciones.id_a);
    modal.esModal = true;

    const ComponenteParaModal = calcComponente({
      configuracion,
      indiceConf,
      id: modal.parametro_id,
      _key: "modal" + configuracion.opciones.id_a,
      nollamar,
      params: modal.data,
      props: modal,
      qsBody: modal.qsBody,
    });
    return (
      <Modal
        open={modal.open}
        modalContainerStyle={{
          minWidth: modal.propsModal?.min_width_modal ?? "60vw",
          zIndex: modal.zIndex + 1,
        }}
        className="modalContainerStyle"
        zIndex={modal.zIndex}
        handleClose={() => {
          // PantallaDispatch({
          //   type: "KILL_CONFIGURACION",
          //   payload: configuracion.opciones.id_a,
          // });
          cerrarModal(configuracion.opciones.id_a);
        }}
        data={configuracion}
      >
        {ComponenteParaModal}
      </Modal>
    );
  }
  const Componente = calcComponente({
    configuracion,
    id,
    _key,
    nollamar,
    indiceConf,
    params,
    props,
    qsBody,
  });
  return <StrictMode>{Componente}</StrictMode>;
};

export default SwitchMaestro;

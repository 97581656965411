import React from "react";
import { Button } from "@mui/material";
import "../../botonera.scss";

const Cancelar = ({ handleCancelar }) => {
  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        onClick={handleCancelar}
        className="button_cancelar"
        sx={{
          color: "primary.main",
          border: "1px solid rgba(0, 111, 122, 0.5)",
        }}
      >
        Cancelar
      </Button>
    </>
  );
};

export default Cancelar;

import React, { useState, useContext, useEffect } from "react";
import FuncionesContext from "../../../context/FuncionesContext";
import PantallaContext from "../../../context/PantallaContext";
import Switch from "@material-ui/core/Switch";
import { useRefDataStore } from "../../../../..";

const Toggle = ({ data, cab, campokey, id_elemento }) => {
  const { superSubmit } = useContext(FuncionesContext);

  const [v, setStore] = useRefDataStore((s) => s[data._key + campokey]);
  const [update_id] = useRefDataStore(
    (s) => s[data._key + cab.update_id_alias]
  );

  const { configuraciones_ref } = useContext(PantallaContext);
  const [value, setValue] = useState(v);
  const [lastValue, setLastvalue] = useState(v);
  const [updateId, setUpdateId] = useState(update_id);

  const nombre = (() => {
    if (cab.nombre_alias) {
      return data[cab.nombre_alias];
    }
    return cab.nombre;
  })();

  const usarValores = cab.usar_valores?.split(",") ?? [];
  let s = usarValores[0]?.toString().trim() ?? "s";
  let n = usarValores[1]?.toString().trim() ?? "n";

  const handleCancelar = () => {
    setValue(v);
    setLastvalue(v);
  };

  const handleChange = async (e) => {
    const valor = e.target.checked ? s : n;
    const { id_a } = cab;

    superSubmit({ valor, id_a, update_id:updateId, handleCancelar, cab, data })
      .then((result) => {
        if (result.status > 300) return setValue(lastValue);
        setLastvalue(() => valor);
        setValue(valor);
        setStore({ [data._key + campokey]: valor });
        if (!updateId) {
          setStore({
            [data._key + cab.update_id_alias]: result.data.registro.id,
          });
          setUpdateId(result.data.registro.id);
        }

        return result;
      })
      .catch((err) => {
        //console.log("Cancelado ", err);
      });
  };

  useEffect(() => {
    if (configuraciones_ref[cab.id_a] === 1) return;

    setValue(v);
    setUpdateId(updateId);
  }, [configuraciones_ref[cab.id_a]]);

  return (
    <div
      id={id_elemento}
      className="tarjeta_grid_item_label_item"
      style={{ justifyContent: cab.align }}
    >
      {nombre ? (
        <div
          className="vista_label"
          style={{ fontWeight: "bold", margin: "6px 0" }}
        >
          {nombre}:
        </div>
      ) : (
        <></>
      )}
      <Switch
        color="primary"
        className="list-toggle"
        checked={
          value === null ? false : value?.toString() === s ? true : false
        }
        onChange={handleChange}
      />
    </div>
  );
};

export default Toggle;

import React, { useState, useContext, useEffect, useRef } from "react";
import FuncionesContext from "../context/FuncionesContext";
import { Editor } from "primereact/editor";
import Guardar from "../ABM/componentes/botonera/Guardar";
import Cancelar from "../ABM/componentes/botonera/Cancelar";
import { ReadOnlyTextArea } from "./EditorTextArea";
import { useRefDataStore } from "../../..";

// EL COMPONENTE REQUIERE QUILL 1.3.7 y PRIMEREACT@5

const NoteTaker = (props) => {
  const { data, cab, campokey, context, id_elemento, setValor, valor } = props;
  const { superSubmit, validarRegex } = useContext(FuncionesContext);

  const { opciones } = useContext(context);

  const [v, setStore] = useRefDataStore((s) => s[data._key + campokey]);
  const [update_id] = useRefDataStore(
    (s) => s[data._key + cab.update_id_alias]
  );

  const [value, setValue] = useState(v ?? valor);
  const [lastValue, setLastvalue] = useState(v ?? valor);
  const [isFocused, setIsFocused] = useState(false);
  const [guardado, setGuardado] = useState(true);
  const [updateId, setUpdateId] = useState(
    update_id ?? data[cab.update_id_alias]
  );

  const miComponenteRef = useRef(null);

  const nombre = (() => {
    if (cab.nombre_alias) {
      return data[cab.nombre_alias];
    }
    return cab.nombre;
  })();

  const disabled = data[cab.id_a + "_disabled"]
    ? data[cab.id_a + "_disabled"] === "s"
    : cab.disabled === "s";

  let classNames = data[cab.id_a + "_className"] ?? cab.className;

  const handleCancelar = () => {
    setValue(v);
    setLastvalue(v);
    setIsFocused(false);
    setGuardado(true);
  };

  class handleGuardar {
    static update = async (e) => {
      const valor = value;

      if (valor === null || lastValue?.toString() === valor.toString().trim())
        return;

      if (!validarRegex(valor, cab.validacion_regex, cab)) {
        setValue(v);
        return;
      }

      const { id_a } = cab;
      setIsFocused(false);

      superSubmit({
        valor,
        id_a,
        update_id: updateId,
        handleCancelar,
        cab,
        data,
      })
        .then((result) => {
          setLastvalue(() => valor);
          setStore({ [data._key + campokey]: valor });
          if (!updateId) {
            setStore({
              [data._key + cab.update_id_alias]: result.data.registro.id,
            });
            setUpdateId(result.data.registro.id);
          }

          setGuardado(true);
          return result;
        })
        .catch((err) => {
          console.log("Cancelado ", err);
        });
    };

    static abm = async (e) => {
      return e;
    };
  }

  class handleChange {
    static abm = (e) => {
      setValue(e.htmlValue);
      setValor(e.htmlValue);
    };
    static update = (e) => {
      setGuardado(e.htmlValue === lastValue );
      setValue(e.htmlValue);
    };
  }

  (() => {
    if (updateId) {
      classNames = `${classNames} 
        ${data[cab.id_a + "_update_className"] ?? cab.update_className}`;
      return;
    }
    classNames = classNames + " sinUpdateId";
    return;
  })();

  useEffect(() => {
    const handleClick = (event) => {
      // Verificar si el clic ocurrió dentro del componente
      if (
        miComponenteRef.current &&
        miComponenteRef.current.contains(event.target)
      ) {
        if (opciones.tipo.id === 9) return;
        setIsFocused(true);
      } else {
        setIsFocused(false);
      }
    };

    // Agregar event listener para clics en cualquier parte del documento
    document.addEventListener("click", handleClick);

    // Limpiar el event listener al desmontar el componente
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  if (disabled) {
    return <ReadOnlyTextArea {...props} />;
  }

  // console.log(cab.id_a, value);
  return (
    <div
      style={{
        width: "100%",
        flexWrap: "wrap",
        border: guardado ? "none" : "2px solid red",
      }}
      id={id_elemento}
      className="tarjeta_grid_item_label_item  d-flex flex-column align-items-start"
      ref={miComponenteRef}
    >
      {nombre ? (
        <div
          className={
            opciones.tipo.id === 9 ? "labelNoteTakerABM" : "labelNoteTaker"
          }
        >
          {nombre}:
        </div>
      ) : (
        <></>
      )}

      <Editor
        disabled={disabled}
        className={
          disabled
            ? classNames + " textArea_disabled"
            : classNames + " inputTextArea"
        }
        id={id_elemento}
        value={value}
        onTextChange={
          opciones.tipo.id === 9 ? handleChange.abm : handleChange.update
        }
        pt={{
          root: {
            style: {
              width: cab.width_input ?? "100%",
            },
          },
        }}
        readOnly={disabled}
        // onBlur={handleGuardar}
      />
      {isFocused ? (
        <div
          style={{ width: "100%", margin: "0.2rem" }}
          className="btns-noteTaker"
        >
          <Guardar
            handleSubmit={
              opciones.tipo.id === 9 ? handleGuardar.abm : handleGuardar.update
            }
            texto_confirmar="Guardar"
            size="small"
          />
          <Cancelar size="small" handleCancelar={handleCancelar} />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default NoteTaker;

export const ABMNoteTaker = (props) => {
  return <div></div>;
};

import React from "react";
import { Button } from "@mui/material";
import "../../botonera.scss";
import { fontTheme } from "../../../helper/themeMui";
import { useContext } from "react";

const ListadoSubmitBoton = ({
  handleSubmit,
  loading,
  text,
  color,
  bg,
  context,
}) => {
  const DaContext = useContext(context);
  return (
    <>
      <Button
        variant="contained"
        size="small"
        onClick={() => {
          handleSubmit(DaContext.datos_seleccionados);
        }}
        disabled={loading}
        style={{
          height: "fit-content",
          marginRight: "20px",
          background: bg,
          color,
          fontFamily: fontTheme.typography.fontFamily,
          width: "fit-content",
        }}
      >
        {text}
      </Button>
    </>
  );
};

export default ListadoSubmitBoton;

import React, { useState, useContext, useEffect, useRef } from "react";
import FuncionesContext from "../../../context/FuncionesContext";
import PantallaContext from "../../../context/PantallaContext";
import ListadoContext from "../../context/ListadoContext";

const SiNoEditable = ({
  data,
  cab,
  campokey,
  key,
  indiceData,
  id_elemento,
}) => {
  const { superSubmit } = useContext(FuncionesContext);

  const { configuraciones_ref } = useContext(PantallaContext);
  const { ListadoDispatch, celdas } = useContext(ListadoContext);
  const [value, setValue] = useState(data[campokey]);
  const [lastValue, setLastvalue] = useState(data[campokey]);
  const [update_id, setUpdate_id] = useState(data[cab.update_id_alias]);

  const id_a = cab.id_a;
  const celdaref = useRef(celdas[id_a + data._key]);

  const handleCancelar = () => {
    setValue(data[campokey]);
    setLastvalue(data[campokey]);
  };

  const handleChange = async (e) => {
    const valor = e.target.value === "s" ? "s" : "n";

    if (valor === null || lastValue?.toString() === valor.toString().trim())
      return;

    const { id_a } = cab;

    setValue(valor);

    superSubmit({ valor, id_a, update_id, handleCancelar, cab, data })
      .then((result) => {
        setLastvalue(valor);

        ListadoDispatch({
          type: "SET_DATO_ESPECIFICO",
          payload: {
            value: result.data.registro.id,
            indiceData,
            key: cab.update_id_alias,
          },
        });

        ListadoDispatch({
          type: "SET_DATO_ESPECIFICO",
          payload: {
            value: valor,
            indiceData,
            key: cab.campo_alias ? cab.campo_alias : id_a,
          },
        });
        return result;
      })
      .catch((err) => {
        console.log("Cancelado ", err);
      });
  };

  useEffect(() => {
    if (configuraciones_ref[cab.id_a] === 1) return;

    setValue(data[campokey]);
    setUpdate_id(data[cab.update_id_alias]);
  }, [configuraciones_ref[cab.id_a]]);

  if (cab.default && !value) {
    setValue(cab.default);
  }

  useEffect(() => {
    // cambia valor al default cuando cambia el valor original del request (celda[id_a+data._key]). 
    if (!celdaref.current) return;
    if (celdas[id_a + data._key] !== celdaref.current) {
      celdaref.current = celdas[id_a + data._key];

      ListadoDispatch({
        type: "SET_DATO_ESPECIFICO",
        payload: {
          key: cab.id_a, // update_id
          indiceData: indiceData, // 4
          value: cab.default,
        },
      });
    }
  }, [celdas[id_a + data._key]]);

  return (
    <div
      id={id_elemento}
      key={key}
      className="tarjeta_grid_item_label_item"
      style={{ textAlign: cab.align ?? "center" }}
    >
      {cab.label ? (
        <div className="vista_label" style={{ fontWeight: "bold" }}>
          {cab.label}:
        </div>
      ) : (
        <></>
      )}

      <select
        onChange={handleChange}
        value={value === null ? "null" : value === "s" ? "s" : "n"}
        style={{ marginLeft: "5px" }}
        className={data[cab.className] ?? cab.className}
      >
        <option value="s" disabled={data[cab.update_id_alias] ? false : true}>
          SI
        </option>
        <option value="n" disabled={data[cab.update_id_alias] ? false : true}>
          NO
        </option>
        <option value="null" disabled></option>
      </select>
    </div>
  );
};

export default SiNoEditable;

import React from "react";
import routes from "../../../routes";

const RedirectP = ({ configuracion }) => {
  const { enlace, enlace_externo } = configuracion.opciones;

  if (enlace_externo === "s") {
    return window.location.replace(enlace);
  }

  const C = routes.find((r) => r.path === enlace).component;
  return <C />;
};

export default RedirectP;

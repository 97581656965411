import React, { useState } from "react";
import Modal from "react-modal";

const modalStyle = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    zIndex: 9000,
  },
  content: {
    maxWidth: "800px",
    margin: "auto",
    padding: "20px",
    zIndex: 9001,
  },
};

const VerArchivo = ({ data, cab, hijos, campokey, id_elemento }) => {
  const e = cab.enlace
    ? cab.enlace_alias
      ? data[cab.enlace_alias]
        ? data[cab.enlace_alias]
        : cab.enlace
      : cab.enlace
    : undefined;

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [fileType, setFileType] = useState(() => {
    const ext = e.split(".").pop();
    if (ext === "pdf") return "pdf";
    if (
      ["jpg", "jpeg", "png", "gif", "bmp", "svg", "tiff", "webp"].includes(ext)
    )
      return "image";
    return "pdf";
  }); // 'image' o 'pdf'
  const [fileUrl, setFileUrl] = useState(process.env.PUBLIC_URL + e);

  const openModal = (type, url) => {
    setFileType(type);
    setFileUrl(url);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div id={id_elemento} className={cab.className}>
      <div
        style={{
          textAlign: "center",
        }}
        onClick={() => openModal(fileType, fileUrl)}
      >
        {data[campokey]}
      </div>
      <div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={modalStyle}
          contentLabel="Visor de Imagen/PDF"
        >
          <div className="cerrar-modal">
            <span
              style={{
                background: "lightgray",
                fontSize: "14px",
                padding: "0.3rem 0.6rem",
                cursor: "pointer",
                borderRadius: "3px",
              }}
              onClick={() => closeModal()}
            >
              X
            </span>
          </div>

          {fileType === "image" && <img src={fileUrl} alt="Imagen" />}

          {fileType === "pdf" && (
            <>
              <iframe
                src={fileUrl}
                type="application/pdf"
                width="100%"
                height="99%"
                style={{ border: "none" }}
                title="test"
              ></iframe>
            </>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default VerArchivo;

import React, { useState, useEffect, useContext } from "react";

import PantallaContext from "../context/PantallaContext";
import { addsql } from "../context/PantallaReducer";
import FuncionesContext from "../context/FuncionesContext";
import { useLocation } from "react-router";

import SwitchMaestro from "../components/SwitchMaestro";

import { Card } from "reactstrap";
import HeaderConf from "../components/HeaderConf";
import ModalesContext from "../context/ModalContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { useRefStore } from "../../..";

const SubPantalla = (props) => {
  const { configuracion, id, nollamar, indiceConf, modalProps, params } = props;
  // console.log("SubPantalla: ", configuracion.opciones.id_a, indiceConf);
  let { qsBody } = props;

  const [store, setStore] = useRefStore();
  const id_a = configuracion.opciones.id_a;

  const { configuraciones_ref, configuraciones_padre } = store;
  const { PantallaDispatch } = useContext(PantallaContext);

  const { getConfiguracion, requestErrorHandler } =
    useContext(FuncionesContext);
  const { getModal } = useContext(ModalesContext);

  const [loadingPantalla, setLoadingPantalla] = useState(false);

  const [subPantallaConfs, setSubPantallaConfs] = useState(configuracion);
  let { search, state: locationState = { filtros: {} } } = useLocation();

  const qs = search?.split("?")[1];

  const ref = localStorage.getItem("referrer");
  const ses = sessionStorage.getItem("referrer");
  const referrer = ref
    ? JSON.parse(localStorage.getItem("referrer"))
    : undefined;
  const session = ses
    ? JSON.parse(sessionStorage.getItem("referrer"))
    : undefined;

  if (qs) {
    const qsPairs = qs.split("&");
    qsBody = (() => {
      let resBody = {};

      qsPairs.forEach((q) => {
        const kp = q.split("=");
        if (!kp[0]) return;
        resBody[kp[0]] = kp[1];
      });
      return resBody;
    })();
  }

  if (referrer) {
    localStorage.removeItem("referrer");
    sessionStorage.setItem("referrer", JSON.stringify(referrer));

    if (referrer.filtros) {
      locationState.filtros = referrer.filtros;
    }
  }
  if (session?.filtros) {
    locationState.filtros = session.filtros;
  }

  useEffect(() => {
    if (nollamar) return;
    //useEffect para actualizar datos a control
    if (configuraciones_ref[id_a] === 1) return;

    // console.log(qsBody, params)
    setLoadingPantalla(true);
    (async () => {
      await getConfiguracion(id_a, qsBody, params, configuraciones_padre[id_a])
        .then((response) => {
          if (response.status >= 400) {
            requestErrorHandler(response);
          }

          PantallaDispatch({
            type: "SET_DATOS_CONF",
            payload: { configuracion: response.data, indiceConf },
          });

          // PantallaDispatch({
          //   type: "ADD_SQL",
          //   payload: response.data.sql,
          // });

          setStore({ sql: addsql(response.data?.sql, store.sql) });

          setSubPantallaConfs(response.data);
          setLoadingPantalla(false);
        })

        .catch((error) => {
          console.log(error);
        });
    })();
  }, [configuraciones_ref[id_a]]);

  //configuraciones opciones orden

  const ConfiguracionesComponentes = subPantallaConfs.configuraciones
    .sort((a, b) => a.opciones.orden - b.opciones.orden)
    .map((item, ic) => {
      return (
        <SwitchMaestro
          key={item.opciones.id_a}
          configuracion={item}
          indiceConf={indiceConf.concat(ic)}
          id={id}
          nollamar={nollamar}
          params={getModal(id_a).data}
          props={modalProps}
          qsBody={qsBody}
        />
      );
    });

  if (subPantallaConfs.opciones.display_container !== "s") {
    return <></>;
  }

  return (
    <Card
      id={id_a}
      className={id_a + "_CONTENEDOR"}
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        gridColumn: subPantallaConfs.opciones.gridSpan ?? "1/-1",
        border: "none",
      }}
    >
      <HeaderConf
        opciones={subPantallaConfs.opciones}
        className="configuracion_pantalla_titulo_secundario"
      />

      {loadingPantalla ? (
        <div
          style={{
            width: "80vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "300px",
            fontSize: "16px",
            color: "#006f7a",
          }}
        >
          <FontAwesomeIcon
            icon={faCircleNotch}
            spin
            style={{ fontSize: "50px" }}
          />
          <div style={{ marginTop: "10px" }}>Cargando...</div>
        </div>
      ) : (
        <>{ConfiguracionesComponentes}</>
      )}
    </Card>
  );
};

export default SubPantalla;
